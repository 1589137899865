import { Button, Col, Form, Input, Typography, Row, Select, DatePicker, Switch, Divider } from 'antd'
import React, { useEffect, useState } from 'react'
import { Formik, ErrorMessage } from 'formik'
import dayjs from 'dayjs'
import { User } from '../../../models/User'
import { useTranslation } from 'react-i18next'
import userService from '../../../services/user.service'
import DropdownCountries from '../../../components/DropdownCountries/DropdownCountries.component'
import DropdownStates from '../../../components/DropdownStates/DropdownStates.component'
import DropdownCities from '../../../components/DropdownCities/DropdownCities.component'

const { Text } = Typography
const UserForm = ({ element, onAdd, onUpdate, isModalOpen }) => {
  const { t } = useTranslation()

  const [isAlertVisible, setIsAlertVisible] = useState(false)
  const [user, setUser] = useState(element ? element : new User())
  const [roles, setRoles] = useState([])

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const validate = (values) => {
    const errors = {}
    if (!values.name) {
      errors.name = 'Required name'
    }
    if (!values.surname) {
      errors.surname = 'Required surname'
    }
    if (!values.user) {
      errors.user = 'Required user'
    }
    if (!values.role) {
      errors.role = 'Required role'
    }
    if (!values.email) {
      errors.email = 'Required email'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address'
    }
    return errors
  }

  useEffect(() => {
    userService.getAllRoles().then((data) => {
      const roles = data.map((x) => ({ value: x.id, label: x.name }))
      setRoles(roles)
    })
  }, [])

  useEffect(() => {
    if (!isModalOpen || !element) return
    const fetchData = async () => {
      try {
        const [userData] = await Promise.all([userService.getUserById(element.id)])

        setUser((prevUser) => ({
          ...prevUser,
          country: element.countryId,
          state: element.state,
          city: element.city,
          user: userData?.username
        }))
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [element, isModalOpen])

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...user, country: user.countryId, state: user.stateId, city: user.cityId }}
      validate={validate}
      validateOnChange={false}
      onSubmit={async (values, actions) => {
        const submitData = {
          ...values,
          username: values.user,
          phone: '1111111111',
          pswd: 'fakepassword',
          postalCode: '00000',
          street: 'no-street',
          roles: typeof values.role == 'number' ? [values.role] : [roles.find((x) => x.label === values.role).value],
          year: Number(values.year),
          country: values.country,
          state: values.state,
          city: values.city
        }

        try {
          if (element) {
            let submitDataHere = { ...submitData }
            delete submitDataHere.actions
            await userService.editUserById(user.id, submitDataHere)
            onUpdate?.()
          } else {
            await userService.addUser(submitData)
            onAdd?.()
          }
          setIsAlertVisible(true)
          actions.setSubmitting(false)
        } catch (error) {
          console.error(error)
        }
      }}
    >
      {(formik) => (
        <Form
          labelAlign="top"
          style={{
            maxWidth: 900
          }}
          onFinish={formik.handleSubmit}
          onFinishFailed={onFinishFailed}
        >
          <Divider orientation="left" className={'form-divider'}>
            User Information
          </Divider>

          <Row gutter={24}>
            <Col xs={24} sm={12} md={9}>
              <Form.Item
                required
                label={t('Name')}
                name="name"
                className={{
                  'input-error': formik.errors.name && formik.touched.name
                }}
              >
                <>
                  <Input name="name" id="name" onChange={formik.handleChange} value={formik.values['name']} />
                  <ErrorMessage name="name" />
                </>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={9}>
              <Form.Item
                required
                label={t('Surname')}
                name="surname"
                className={{
                  'input-error': formik.errors.surname && formik.touched.surname
                }}
              >
                <>
                  <Input name="surname" id="surname" onChange={formik.handleChange} value={formik.values['surname']} />
                  <ErrorMessage name="surname" />
                </>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Form.Item
                label={t('Gender')}
                name="gender"
                className={{
                  'input-error': formik.errors.gender && formik.touched.gender
                }}
                showSearch
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              >
                <>
                  <Select
                    name="gender"
                    id="gender"
                    value={formik.values['gender']}
                    options={[
                      { value: 'M', label: 'Male' },
                      { value: 'F', label: 'Female' }
                    ]}
                    onChange={(value) => {
                      formik.setFieldValue('gender', value)
                    }}
                  />
                  <ErrorMessage name="gender" />
                </>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Email')}
                name="email"
                className={{
                  'input-error': formik.errors.email && formik.touched.email
                }}
              >
                <>
                  <Input name="email" id="email" onChange={formik.handleChange} value={formik.values['email']} />
                  <ErrorMessage name="email" />
                </>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('User')}
                name="user"
                className={{
                  'input-error': formik.errors.user && formik.touched.user
                }}
              >
                <>
                  <Input name="user" id="user" onChange={formik.handleChange} value={formik.values['user']} />
                  <ErrorMessage name="user" />
                </>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                required
                label={t('Role')}
                name="role"
                className={{
                  'input-error': formik.errors.role && formik.touched.role
                }}
              >
                <>
                  <Select
                    name="role"
                    id="role"
                    value={formik.values['role']}
                    options={roles}
                    onChange={(value) => {
                      formik.setFieldValue('role', value)
                    }}
                    showSearch
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  />
                  <ErrorMessage name="role" />
                </>
              </Form.Item>
            </Col>

            <Col xs={12} md={8}>
              <Form.Item
                label={t('Month')}
                name="month"
                className={{
                  'input-error': formik.errors.month && formik.touched.month
                }}
              >
                <>
                  <Select
                    name="month"
                    id="month"
                    value={formik.values['month']}
                    onChange={(value) => {
                      formik.setFieldValue('month', value)
                    }}
                    options={[
                      { value: 1, label: 'January' },
                      { value: 2, label: 'February' },
                      { value: 3, label: 'March' },
                      { value: 4, label: 'April' },
                      { value: 5, label: 'May' },
                      { value: 6, label: 'June' },
                      { value: 7, label: 'July' },
                      { value: 8, label: 'August' },
                      { value: 9, label: 'September' },
                      { value: 10, label: 'October' },
                      { value: 11, label: 'November' },
                      { value: 12, label: 'December' }
                    ]}
                    showSearch
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  />
                  <ErrorMessage name="month" />
                </>
              </Form.Item>
            </Col>
            <Col xs={12} md={8}>
              <Form.Item
                label={t('Year')}
                name="year"
                className={{
                  'input-error': formik.errors.year && formik.touched.year
                }}
              >
                <>
                  <DatePicker
                    name="year"
                    id="year"
                    style={{ width: '100%' }}
                    value={dayjs(String(formik.values['year']), 'YYYY')}
                    onChange={(date, dateString) => {
                      formik.setFieldValue('year', dateString)
                    }}
                    picker="year"
                  />
                  <ErrorMessage name="year" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <DropdownCountries
                error={formik.errors.country}
                value={formik.values.country}
                onChange={(value) => {
                  formik.setFieldValue('country', value)
                  formik.setFieldValue('state', null)
                  formik.setFieldValue('city', null)
                }}
              />
            </Col>

            <Col xs={24} md={12}>
              <DropdownStates
                error={formik.errors.state}
                country_id={formik.values.country}
                value={formik.values.state}
                onChange={(value) => {
                  formik.setFieldValue('state', value)
                  formik.setFieldValue('city', null)
                }}
              />
            </Col>

            <Col xs={24} md={12}>
              <DropdownCities
                error={formik.errors.city}
                state_id={formik.values.state}
                value={formik.values.city}
                onChange={(value) => formik.setFieldValue('city', value)}
                originEntity='users'
              />
            </Col>

            <Col span={24}>
              <Divider orientation="left" className={'form-divider'}>
                User Access
              </Divider>
            </Col>

            <Col span={12}>
              <Form.Item
                label={t('API Access')}
                name="api_access"
                className={{
                  'input-error': formik.errors.api_access && formik.touched.api_access
                }}
              >
                <>
                  <Switch
                    defaultChecked={user['api_access']}
                    onChange={(value) => {
                      formik.setFieldValue('api_access', value)
                    }}
                  />
                </>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('Manager')}
                name="manager"
                className={{
                  'input-error': formik.errors.manager && formik.touched.manager
                }}
              >
                <>
                  <Switch
                    defaultChecked={user['is_manager']}
                    onChange={(value) => {
                      formik.setFieldValue('manager', value)
                    }}
                  />
                </>
              </Form.Item>
            </Col>

            <Col span={24}>
              <hr />
            </Col>

            <Col span={24}>
              <Form.Item
                wrapperCol={{
                  xs: { span: 24, offset: 0 },
                  sm: { span: 12, offset: 6 }
                }}
              >
                <Button style={{ width: '100%' }} type="primary" htmlType="submit">
                  Publish
                </Button>
              </Form.Item>
            </Col>
            {isAlertVisible && (
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16
                }}
              >
                <Text type="success">User {element ? 'edited' : 'added'}!</Text>
              </Form.Item>
            )}
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default UserForm
