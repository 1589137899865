export class RoadRoute {
  constructor() {
    this.country_id = null
    this.date = undefined
    this.description = ''
    this.description_local = ''
    this.email = ''
    this.geographical_scope_id = null
    this.group_route = 0
    this.heritages = []
    this.id = 0
    this.id_user = 0
    this.keywords = []
    this.links = []
    this.name = ''
    this.org_pers_information = ''
    this.tags = []
  }
}
