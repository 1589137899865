export const columns = [
  {
    name: 'Date',
    key: 'created_at',
    filter: 'text'
  },
  {
    name: 'Name',
    key: 'name',
    filter: 'text'
  },
  {
    name: 'Latitude',
    key: 'latitude',
    filter: 'text'
  },
  {
    name: 'Longitude',
    key: 'longitude',
    filter: 'text'
  }
]
