import React, { useState, useEffect } from 'react';
import { Input, List, Avatar, Image } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { cityOcityService } from '../../../services/city_ocity.service';
import heritageService from '../../../services/heritage.service';
import { useNavigate } from 'react-router-dom';
import { getCategoryIcon } from '../../../utils/helpers';
import markerLocationRed from '../../../assets/mapIcons/ocity_marker.png';
import roadService from '../../../services/road.service';

const SearchNavbar = (props) => {
  const { isMobile } = props
  const navigate = useNavigate();
  const [searchExpanded, setSearchExpanded] = useState(false);
  const [citySearch, setCitySearch] = useState('');
  const [cityList, setCityList] = useState('');
  const [heritageList, setHeritageList] = useState('');
  const [routesList, setRoutesList] = useState('');

  const handleSearchCity = async () => {
    const citiesList = await cityOcityService.getCities(0, 0, { city_name: citySearch });
    const herilist = await heritageService.getAllHeritages(0, 0, { name: citySearch });
    const routelist = await roadService.getAllRoadRoutes(0, 0, { name: citySearch })

    const cityData = citiesList?.result?.map((city) => ({
      id: city.city_id,
      name: city.city.name,
      image: city.image,
      latitude: city.latitude,
      longitude: city.longitude
    }));


    const heritageData = herilist?.result?.map((heritage) => ({
      id: heritage.id,
      name: heritage.name,
      image: heritage.image,
      city_id: heritage.city_id,
      heritage_field_id: heritage.heritage_field_id,
      latitude: heritage.latitude,
      longitude: heritage.longitude
    }))

    const routeData = routelist?.result?.map((route) => ({
      id: route.id,
      name: route.name,
      image: route?.image || "",
      latitude: route?.latitude || "",
      longitude: route?.longitude || "",

    }));

    setCityList(cityData);
    setHeritageList(heritageData);
    setRoutesList(routeData);
  };


  useEffect(() => {
    if (citySearch.length > 2) {
      handleSearchCity(citySearch)
    } else if (citySearch.length === 0) {
      setCityList([]);
      setHeritageList([]);
      setRoutesList([]);
    }
  }, [citySearch])

  const toggleSearch = () => {
    setSearchExpanded(!searchExpanded)
  }

  const handleCityClick = (record) => {
    console.log(record)
    navigate(`/city/${record.id}`);
    setCityList([]);
    setCitySearch('');
    setRoutesList([])
    setHeritageList([]);
  };

  const handleHeritagesClick = (record) => {
    navigate(`/city/${record.city_id}/heritage/${record.id}`);
    setCityList([]);
    setHeritageList([]);
    setCitySearch('');
    setRoutesList([])
  };


  const handleRouteClick = (record) => {
    navigate(`/route/${record.id}`);
    setCityList([]);
    setCitySearch('');
    setRoutesList([])
    setHeritageList([]);
  };

  return (
    <div
      style={{
        position: 'absolute',
        right: searchExpanded ? '50%' : (isMobile ?
          "110px" : "300px"),
        transform: searchExpanded ? 'translate(50%, -50%)' : 'translateY(-50%)',
        transition: 'right 0.3s ease, transform 0.3s ease',
        display: 'flex',
        alignItems: 'center',
        top: '50%',
      }}
    >
      <Input
        style={{
          width: searchExpanded ? (isMobile ?
            "100px" : "500px") : '0',
          transition: 'width 0.3s ease',
          opacity: searchExpanded ? 1 : 0,
          marginRight: '10px',
        }}
        placeholder="Buscar..."
        allowClear
        size="large"
        onChange={(e) => setCitySearch(e.target.value)}
      />
      <SearchOutlined
        onClick={toggleSearch}
        style={{ color: '#fff', fontSize: 'large', cursor: 'pointer' }}
      />
      {(cityList.length > 0 || heritageList.length > 0 || routesList.length > 0) && (
        <div
          style={{
            position: 'absolute',
            top: '60px',
            background: '#fff',
            borderRadius: '8px',
            padding: '10px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            zIndex: 1000,
            width: isMobile ?
              "240px" : "500px",
            maxHeight: '300px',
            overflowY: 'auto'
          }}
        >
          {/*<div style={{ textAlign: 'center', marginTop: '16px' }}>
            <Paragraph>Cities</Paragraph>
          </div>
          <Divider orientation="center" plain />*/
          }
          {cityList.length > 0 && <List
            itemLayout="horizontal"
            dataSource={cityList}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar src={item.image} shape="square" />}
                  title={item.name}
                  onClick={() => handleCityClick(item)}
                  style={{ cursor: 'pointer' }}
                />
                <Image src={markerLocationRed} width={20} height={20} preview={false} />
              </List.Item>
            )}
          />}
          {/*
            <Divider orientation="center" plain />
          <div style={{ textAlign: 'center', marginTop: '16px' }}>
            <Paragraph>Heritages</Paragraph>
          </div>
          <Divider orientation="center" plain />
          */}
          {heritageList.length > 0 && <List
            itemLayout="horizontal"
            dataSource={heritageList}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar src={item.image} shape="square" />}
                  title={item.name}
                  onClick={() => handleHeritagesClick(item)}
                  style={{ cursor: 'pointer' }}
                />
                <Image src={getCategoryIcon(item.heritage_field_id)} width={20} height={20} preview={false} />
              </List.Item>
            )}
          />}

          {routesList.length > 0 && <List
            itemLayout="horizontal"
            dataSource={routesList}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar src={getCategoryIcon(9)} shape="square" />}
                  title={item.name}
                  onClick={() => handleRouteClick(item)}
                  style={{ cursor: 'pointer' }}
                />
                <Image
                  src={getCategoryIcon(9)}
                  width={20}
                  height={20}
                  preview={false}
                />
              </List.Item>
            )}
          />}
        </div>
      )}
    </div>
  )
}

export default SearchNavbar
