const road_routes_keywords = [
  {
    label: 'ADVENTURE',
    value: 1
  },
  {
    label: 'WELLNESS',
    value: 2
  },
  {
    label: 'SPORTS',
    value: 3
  },
  {
    label: 'SHOPPING',
    value: 4
  },
  {
    label: 'BEACH AND SUN',
    value: 5
  },
  {
    label: 'TRECKING/CYCLING',
    value: 6
  },
  {
    label: 'CULTURAL',
    value: 7
  },
  {
    label: 'FILM',
    value: 8
  },
  {
    label: 'HERITAGE',
    value: 9
  },
  {
    label: 'PARTY',
    value: 10
  },
  {
    label: 'GASTRONOMY',
    value: 11
  },
  {
    label: 'INDUSTRIAL',
    value: 12
  },
  {
    label: 'URBAN',
    value: 13
  },
  {
    label: 'RELIGIOUS',
    value: 14
  },
  {
    label: 'MEMORIAL',
    value: 15
  },
  {
    label: 'NATURE',
    value: 16
  }
]

export default road_routes_keywords
