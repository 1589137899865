import '../../styles/App.css';
import { Link } from 'react-router-dom';
import { Space, Select, Avatar, Badge } from 'antd';
import { BellOutlined, UserOutlined } from '@ant-design/icons';
import { useAuth, useLocaleUpdate } from '../../Hooks/index';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import NotificationsDrawer from '../NotificationsDrawer';
import LeftMenuHooks from '../LeftMenu/LeftMenuHooks.hooks';
import i18n from '../../i18n';
import SearchComponent from './SearchNavbar/SearchNavbar.component'; // Importamos el componente de búsqueda
import ocityLogo from '../../assets/logo-ocity-beta-400.png';

function NavBar() {
  const { toggleMenu } = LeftMenuHooks();
  const { isAuthenticated } = useAuth();
  const changeLanguage = useLocaleUpdate();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleLangChange = (value) => {
    changeLanguage(value)
    i18n.changeLanguage(value)
  }

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <nav style={{ backgroundColor: '#263238', zIndex: 1 }} className="navbar navbar-expand-lg navbar-light fixed-top">
      <div className="d-flex justify-content-between" style={{ width: '100%', marginLeft: '5rem',marginRight:"1rem" }}>
        <div
          className="navbar-brand burger-menu"
          onClick={() => {
            toggleMenu();
          }}
        >
          <i className="bi bi-list"></i>
        </div>
        {!isMobile && <Link className="navbar-brand" to={'/'}>
          <img src={ocityLogo} width="100" alt="LOGO OCITY"></img>
        </Link>}
        <div className="collapse navbar-collapse" id="" style={{ alignItems:"center",justifyItems:"center",display:"flex" }}>
          <ul className="navbar-nav ml-auto">
            <Space size={23}>
              <SearchComponent isMobile={isMobile}/> 
              {!isMobile && <Select
                defaultValue="🇬🇧 English"
                style={{ width: 120 }}
                onChange={handleLangChange}
                options={[
                  { value: 'en_US', label: '🇬🇧 English' },
                  { value: 'es_ES', label: '🇪🇸 Español' }
                ]}
              /> }
              {isAuthenticated ? (
                <>
                  <NotificationsDrawer
                    onClose={onClose}
                    open={open}
                    notifications={[
                      {
                        title: 'Notification CIUDAD',
                        description: 'Se ha añadido una nueva ciudad. Ven a visitarla.'
                      },
                      {
                        title: 'Notification PUEBLO',
                        description: 'Se ha añadido un nuevo pueblo. Ven a visitarnos.'
                      },
                      {
                        title: 'Notification Valencia',
                        description: 'Son fallas en Valencia. 14 - 19 Marzo'
                      }
                    ]}
                  />
                  <Badge count={1} overflowCount={10}>
                    <BellOutlined onClick={showDrawer} style={{ color: '#fff', fontSize: 'large' }} />
                  </Badge>
                  <Link className="nav-link" to={'/profile'} style={{ borderRadius: '10px' }}>
                    <Avatar size="large" icon={<UserOutlined style={{ color: '#fff', fontSize: 'large' }} />} />
                  </Link>
                </>
              ) : (
                <Link className="nav-link" to={'/sign-in'}>
                  {t('Login')}
                </Link>
              )}
            </Space>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default NavBar
