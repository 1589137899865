export class Ambassador {
  constructor() {
    this.id = 0
    this.name = ''
    this.country_id = null
    this.state_id = null
    this.users = []
    this.managers = []
    this.website = ''
    this.studies_interests = []
    this.links_interest = []
    this.latitude = 0
    this.longitude = 0
    this.image = ''
  }
}
