import React, { useEffect, useState } from 'react'
import { Input, Modal, Table, Image } from 'antd'
import { useNavigate } from 'react-router-dom'
import { cityOcityService } from '../../../../services/city_ocity.service'
import heritageService from '../../../../services/heritage.service'
import markerLocationRed from '../../../../assets/mapIcons/ocity_marker.png'
import LANDMARK_INDEX_ICON_ALT from '../../../../assets/mapIcons/landmark_marker_active.png'
import BOOK_INDEX_ICON_ALT from '../../../../assets/mapIcons/book_marker_active.png'
import THEATRE_INDEX_ICON_ALT from '../../../../assets/mapIcons/theatre_marker_active.png'
import TREE_INDEX_ICON_ALT from '../../../../assets/mapIcons/tree_marker_active.png'
import LANDSCAPE_INDEX_ICON_ALT from '../../../../assets/mapIcons/landscape_marker_active.png'
import EVENT_INDEX_ICON_ALT from '../../../../assets/mapIcons/event_marker_active.png'
import WILDLIFE_INDEX_ICON_ALT from '../../../../assets/mapIcons/wildlife_marker_active.png'
import MIXED_INDEX_ICON_ALT from '../../../../assets/mapIcons/mix_marker_active.png'

const SearchCityModal = ({ modalSearchCity, setModalSearchCity }) => {
  const navigate = useNavigate()
  const [citySearch, setCitySearch] = useState('')
  const [cityList, setCityList] = useState([])
  const [heritagesList, setHeritagesList] = useState([])

  const cityColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (_, data) => (
        <Image
          src={process.env.REACT_APP_SERVER_IP + data?.image}
          alt="city"
          style={{ width: '50px', height: '50px' }}
        />
      )
    },
    {
      title: 'Icon',
      dataIndex: 'Icon',
      key: 'Icon',
      render: () => <img src={markerLocationRed} alt="icon" style={{ width: '20px', height: '25px' }} />
    }
  ]

  const heritageColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (_, data) => (
        <Image
          src={process.env.REACT_APP_SERVER_IP + data?.image}
          alt="heritage"
          style={{ width: '50px', height: '50px' }}
        />
      )
    },
    {
      title: 'Icon',
      dataIndex: 'icon',
      key: 'icon',
      render: (_, data) => {
        const activeIconData = {
          1: LANDMARK_INDEX_ICON_ALT,
          2: BOOK_INDEX_ICON_ALT,
          3: THEATRE_INDEX_ICON_ALT,
          4: TREE_INDEX_ICON_ALT,
          5: LANDSCAPE_INDEX_ICON_ALT,
          6: EVENT_INDEX_ICON_ALT,
          7: WILDLIFE_INDEX_ICON_ALT,
          8: MIXED_INDEX_ICON_ALT
        }
        return <img src={activeIconData[data.heritage_field_id]} alt="icon" style={{ width: '20px', height: '25px' }} />
      }
    }
  ]

  const handleCityClick = (record) => {
    navigate(`/city/${record.id}`, { replace: true })
    setModalSearchCity(false)
    setCityList([])
    setHeritagesList([])
    setCitySearch('')
  }

  const handleHeritagesClick = (record) => {
    navigate(`/city/${record.city_id}/heritage/${record.id}`, { replace: true })
    setModalSearchCity(false)
    setCityList([])
    setHeritagesList([])
    setCitySearch('')
  }

  useEffect(() => {
    if (citySearch.length > 2) {
      handleSearchCity()
    }
    if (citySearch.length === 0) {
      setCityList([])
      setHeritagesList([])
    }
  }, [citySearch])

  const handleSearchCity = async () => {
    const citiesList = await cityOcityService.getCities(0, 0, { city_name: citySearch })
    const heritagesList = await heritageService.getAllHeritages(0, 0, { name: citySearch })

    setCityList(
      citiesList?.result?.map((city) => ({
        id: city.city.id,
        name: city.city.name,
        image: city.city.image,
        latitude: city.latitude,
        longitude: city.longitude
      }))
    )

    setHeritagesList(
      heritagesList?.result?.map((heritage) => ({
        id: heritage.id,
        name: heritage.name,
        image: heritage.image,
        city_id: heritage.city_id,
        heritage_field_id: heritage.heritage_field_id,
        latitude: heritage.latitude,
        longitude: heritage.longitude
      }))
    )
  }

  return (
    <Modal
      closable={true}
      maskClosable={true}
      width={'70%'}
      footer={false}
      title={'Home Search'}
      open={modalSearchCity}
      onCancel={() => setModalSearchCity(false)}
    >
      <div className="mb-3">
        <div className="row">
          <div className="col-6 mb-3">
            <Input
              type="search"
              name="citySearch"
              id="citySearch"
              onChange={(e) => setCitySearch(e.target.value)}
              value={citySearch}
              placeholder="Search for cities and heritages"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 mb-3">
            <h6>City</h6>
            <Table
              dataSource={cityList}
              columns={cityColumns}
              pagination={{
                total: cityList.length,
                showSizeChanger: true,
                pageSize: 5,
                pageSizeOptions: ['5', '10', '20', '50']
              }}
              rowKey="id"
              onRow={(record) => ({
                onClick: () => handleCityClick(record)
              })}
            />
          </div>
          <div className="col-md-6 mb-3">
            <h6>Heritage</h6>
            <Table
              dataSource={heritagesList}
              columns={heritageColumns}
              pagination={{
                total: heritagesList.length,
                showSizeChanger: true,
                pageSize: 5,
                pageSizeOptions: ['5', '10', '20', '50']
              }}
              rowKey="id"
              onRow={(record) => ({
                onClick: () => handleHeritagesClick(record)
              })}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default SearchCityModal
