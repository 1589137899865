import { Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

export const InfoTooltip = ({ text }) => {
  return (
    <Tooltip title={text}>
      <InfoCircleOutlined style={{ marginLeft: 8, color: '#1890ff', cursor: 'pointer' }} />
    </Tooltip>
  )
}
