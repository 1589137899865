/* eslint-disable no-unused-vars */
import React, { forwardRef, useEffect } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
const CaptchaComponent = forwardRef((props, ref) => {
  // eslint-disable-next-line no-undef
  const siteKey = process.env.REACT_APP_SITE_KEY
  return (
    <div style={{ marginTop: '10px', marginBottom: '15px' }}>
      {/*<div><label>Completa el captcha para verificar que no eres un robot:</label></div>*/}
      <ReCAPTCHA sitekey={siteKey} ref={ref} onChange={props.onChange} />
      {props?.isVerified && <p style={{ color: 'green' }}>Captcha verificado con éxito!</p>}
    </div>
  )
})

export default CaptchaComponent
