import { Modal, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import ModalSupportForm from '../ModalSupportForm/modalSupportForm.component'
const ConfirmModal = ({ visible, onCancel, onConfirm, haveForm }) => {
  const { t } = useTranslation()

  return (
    <Modal
      open={visible}
      title={haveForm ? <ModalSupportForm /> : t('Confirmation')}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          {t('Cancel')}
        </Button>,
        <Button key="confirm" type="primary" onClick={onConfirm}>
          {t('Accept')}
        </Button>
      ]}
    >
      <p>{haveForm ? t('Support confirmation') : t('Redirect To Other Web')}</p>
    </Modal>
  )
}

export default ConfirmModal
