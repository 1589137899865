import React, { useState } from 'react'
import { theme } from 'antd'
import { InfoOutlined } from '@ant-design/icons'
import Modal from 'antd/es/modal/Modal'

import UpdateItemForm from '../../UpdateItemForm'
import HeritageForm from '../../../pages/Heritage/Heritage/components/heritageForm.component.js'
import HeritageContentForm from '../../../pages/Heritage/HeritageContent/components/heritageContentForm.component.js'
import RoadRouteForm from '../../../pages/Road/RoadRoute/components/roadRouteForm.component'
import HeritageProposalForm from '../../../pages/Heritage/HeritageProposal/components/heritageProposalForm.component.js'
import StateForm from '../../../pages/Cities/States/components/stateForm.component.js'
import CountryForm from '../../../pages/Cities/Countries/components/countriesForm.component.js'
import AmbassadorViewModal from '../../../pages/Ambassadors/components/ambassadorView.component.js'
import CityOcityView from '../../../pages/Cities/Ocity/components/ocityView.component.js'
import UserView from '../../../pages/Users/components/userView.component.js'
import CityView from '../../../pages/Cities/Cities/components/cityView.component.js'

const { useToken } = theme
/**
 *
 * @param {*} param0
 * @returns html of a column element
 */
const InfoButton = ({ inputAttributes, element, entity, onUpdate }) => {
  const { token } = useToken()
  // const { t } = useTranslation()

  // Edit an element modal view
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)

  function openEditModal() {
    setIsEditModalOpen(true)
  }

  function handleEditCancel() {
    setIsEditModalOpen(false)
  }
  // -------------------------------

  function renderView(entity) {
    let form
    switch (entity) {
      case 'user': {
        form = <UserView element={element} isModalOpen={isEditModalOpen} />
        break
      }

      case 'ocity': {
        form = <CityOcityView element={element} isModalOpen={isEditModalOpen} />
        break
      }

      case 'city': {
        form = <CityView element={element} isModalOpen={isEditModalOpen} />
        break
      }

      case 'state': {
        form = (
          <StateForm
            element={element}
            isModalOpen={isEditModalOpen}
            onUpdate={() => {
              onUpdate?.()
              setIsEditModalOpen(false)
            }}
          />
        )
        break
      }

      case 'country': {
        form = (
          <CountryForm
            element={element}
            isModalOpen={isEditModalOpen}
            onUpdate={() => {
              onUpdate?.()
              setIsEditModalOpen(false)
            }}
          />
        )
        break
      }

      case 'ambassador': {
        form = (
          <AmbassadorViewModal
            element={element}
            isModalOpen={isEditModalOpen}
            onUpdate={() => {
              onUpdate?.()
              setIsEditModalOpen(false)
            }}
          />
        )
        break
      }

      case 'heritage': {
        form = (
          <HeritageForm
            element={element}
            isModalOpen={isEditModalOpen}
            onUpdate={() => {
              onUpdate?.()
              setIsEditModalOpen(false)
            }}
          />
        )
        break
      }

      case 'heritage proposal': {
        form = (
          <HeritageProposalForm
            element={element}
            isModalOpen={isEditModalOpen}
            onUpdate={() => {
              onUpdate?.()
              setIsEditModalOpen(false)
            }}
            onPublish={() => {
              onUpdate?.()
              setIsEditModalOpen(false)
            }}
          />
        )
        break
      }

      case 'heritage content': {
        form = (
          <HeritageContentForm
            element={element}
            isModalOpen={isEditModalOpen}
            onUpdate={() => {
              onUpdate?.()
              setIsEditModalOpen(false)
            }}
          />
        )
        break
      }

      case 'road route': {
        form = (
          <RoadRouteForm
            element={element}
            isModalOpen={isEditModalOpen}
            onUpdate={() => {
              onUpdate?.()
              setIsEditModalOpen(false)
            }}
          />
        )
        break
      }

      default: {
        form = <UpdateItemForm inputAttributes={inputAttributes} element={element} />
        break
      }
    }

    return form
  }

  return (
    <>
      <InfoOutlined style={{ fontSize: 'large', color: token.colorPrimary }} onClick={openEditModal} />
      <Modal width={900} footer={null} open={isEditModalOpen} onCancel={handleEditCancel}>
        {renderView(entity)}
      </Modal>
    </>
  )
}

export default InfoButton
