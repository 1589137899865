import { Divider, Row, Col, Image } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LocationSelector from '../../../../components/LocationSelector/locationSelector.component'

const CityOcityView = ({ element }) => {
  const { t } = useTranslation()
  const [city, setCity] = useState(null)

  useEffect(() => {
    if (element) {
      setCity(element)
    }
  }, [element])

  if (!city) return null

  return (
    <>
      <Divider>{t('City Information')}</Divider>
      <Row gutter={16}>
        <Col>
          <p>
            <strong>{t('Countrie')}:</strong> {city?.country?.name}
          </p>
          <p>
            <strong>{t('State')}:</strong> {city?.state?.name}
          </p>
          <p>
            <strong>{t('City')}:</strong> {city?.city?.name}
          </p>
        </Col>
      </Row>

      <Divider>{t('Ambassador')}</Divider>
      <p>
        <strong>{t('Ambassador')}:</strong> {city?.ambassador?.name ? city?.ambassador?.name : 'Not found'}
      </p>

      <Divider>{t('Validators')}</Divider>
      <ul>
        <li key={1}>
          <p>
            <strong>{t('Validator')}:</strong> {city?.validators_names}
          </p>
        </li>
      </ul>

      <Divider>{t('Hall')}</Divider>
      <p>
        <strong>{t('Hall name')}:</strong> {city.hall_name}{' '}
      </p>
      <p>
        <strong>{t('Hall email')}:</strong> {city.hall_email}{' '}
      </p>

      <Divider>{t('Description')}</Divider>
      <p>
        <strong>{t('Description, quotation, slogan (EN)')}:</strong> {city.description}
      </p>
      <p>
        <strong>{t('Description, quotation, slogan (Local)')}:</strong> {city.description_local}
      </p>

      <Divider>{t('City Networks / Links')}</Divider>
      <ul>
        {city.links.map((network, index) => (
          <li key={index}>
            <p>
              <strong>{t('Name')}:</strong> {network.name}
            </p>
            <p>
              <strong>{t('Name English')}:</strong> {network.name_english}
            </p>
            <p>
              <strong>{t('URL')}:</strong>{' '}
              <a href={network.url} target="_blank" rel="noopener noreferrer">
                {network.url}
              </a>
            </p>
          </li>
        ))}
      </ul>

      <Divider>{t('Image')}</Divider>
      {city.image && <Image width={200} src={city.image} alt="City Image" />}

      <Divider>{t('Enrollment Letter')}</Divider>
      {city.enrollment_letter && city.enrollment_letter != 'null' ? (
        <a href={city.enrollment_letter} target="_blank" rel="noopener noreferrer">
          {' '}
          {t('View Enrollment Letter')}
        </a>
      ) : (
        <p> Not found</p>
      )}

      <Divider>{t('Location')}</Divider>
      <Row gutter={16}>
        <Col span={12}>
          <p>
            <strong>{t('Latitude')}:</strong> {city.latitude}
          </p>
        </Col>
        <Col span={12}>
          <p>
            <strong>{t('Longitude')}:</strong> {city.longitude}
          </p>
        </Col>
      </Row>
      <LocationSelector height="300px" lat={city.latitude} lng={city.longitude} />
    </>
  )
}

export default CityOcityView
