import http from '../http-common'
import { ENV } from '../utils'

class DraftService {
  async saveDraft(key, draft) {
    const { data } = await http.post(`/api/${ENV.API_ROUTES.DRAFT}`, { key, value: draft })
    return data
  }

  async getDraft(key) {
    try {
      const { data } = await http.get(`/api/${ENV.API_ROUTES.DRAFT}/${key}`)
      return data
    } catch (error) {
      return null
    }
  }

  async removeDraft(key) {
    const { data } = await http.delete(`/api/${ENV.API_ROUTES.DRAFT}/${key}`)
    return data
  }
}

export const draftService = new DraftService()
