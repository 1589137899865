export class User {
  constructor() {
    this.id = 0
    this.name = ''
    this.surname = ''
    this.email = ''
    this.user = ''
    this.password = ''
    this.country = ''
    this.city = ''
    this.role = ''
    this.api_access = 1
    this.manager = 1
    this.subscriber_city = ''
    this.gender = ''
    this.year = String(new Date().getFullYear())
    this.month = ''
  }
}
