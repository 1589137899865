const heritage_tags = [
  { id: 1, type: 'NATURAL', subtype: 'AREA', subtype_two: null, tag: 'NATURAL PARK' },
  { id: 2, type: 'NATURAL', subtype: 'AREA', subtype_two: null, tag: 'GEOPARK' },
  { id: 3, type: 'NATURAL', subtype: 'AREA', subtype_two: null, tag: 'NATURAL RESERVE' },
  { id: 4, type: 'NATURAL', subtype: 'AREA', subtype_two: null, tag: 'LANDSCAPE' },
  { id: 5, type: 'NATURAL', subtype: 'AREA', subtype_two: null, tag: 'WETLAND' },
  { id: 6, type: 'NATURAL', subtype: 'AREA', subtype_two: null, tag: 'MARINE AREA' },
  { id: 7, type: 'NATURAL', subtype: 'AREA', subtype_two: null, tag: 'BIOSPHERE' },
  { id: 8, type: 'NATURAL', subtype: 'MONUMENT', subtype_two: null, tag: 'AEOLIAN' },
  { id: 9, type: 'NATURAL', subtype: 'MONUMENT', subtype_two: null, tag: 'CRYOGENIC LANDFORMS' },
  { id: 10, type: 'NATURAL', subtype: 'MONUMENT', subtype_two: null, tag: 'EROSION LANDFORMS' },
  { id: 11, type: 'NATURAL', subtype: 'MONUMENT', subtype_two: null, tag: 'FLUVIAL LANDFORMS' },
  { id: 12, type: 'NATURAL', subtype: 'MONUMENT', subtype_two: null, tag: 'IMPACT LANDFORMS' },
  { id: 13, type: 'NATURAL', subtype: 'MONUMENT', subtype_two: null, tag: 'KARST LANDFORMS' },
  { id: 14, type: 'NATURAL', subtype: 'MONUMENT', subtype_two: null, tag: 'LACUSTRINE LANDFORMS' },
  { id: 15, type: 'NATURAL', subtype: 'MONUMENT', subtype_two: null, tag: 'MOUNTAIN AND GLACIAL LANDFORMS' },
  { id: 16, type: 'NATURAL', subtype: 'MONUMENT', subtype_two: null, tag: 'SLOPE LANDFORMS' },
  { id: 17, type: 'NATURAL', subtype: 'MONUMENT', subtype_two: null, tag: 'TECTONIC LANDFORMS' },
  { id: 18, type: 'NATURAL', subtype: 'MONUMENT', subtype_two: null, tag: 'VOLCANIC LANDFORMS' },
  { id: 19, type: 'NATURAL', subtype: 'MONUMENT', subtype_two: null, tag: 'WEATHERING LANDFORMS' },
  { id: 20, type: 'NATURAL', subtype: 'FLORA AND FAUNA', subtype_two: null, tag: 'CLOSED FORESTS' },
  { id: 21, type: 'NATURAL', subtype: 'FLORA AND FAUNA', subtype_two: null, tag: 'WOODLANDS' },
  {
    id: 22,
    type: 'NATURAL',
    subtype: 'FLORA AND FAUNA',
    subtype_two: null,
    tag: 'FOURR\u00c9S (THICKETS OR SHRUBLANDS)'
  },
  {
    id: 23,
    type: 'NATURAL',
    subtype: 'FLORA AND FAUNA',
    subtype_two: null,
    tag: 'DWARF SCRUB AND RELATED COMMUNITIES'
  },
  { id: 24, type: 'NATURAL', subtype: 'FLORA AND FAUNA', subtype_two: null, tag: 'TERRESTRIAL HERBACEOUS COMMUNITIES' },
  {
    id: 25,
    type: 'NATURAL',
    subtype: 'FLORA AND FAUNA',
    subtype_two: null,
    tag: 'DESERTS AND OTHER SCARCELY VEGETATED AREAS'
  },
  { id: 26, type: 'NATURAL', subtype: 'FLORA AND FAUNA', subtype_two: null, tag: 'AQUATIC PLANT FORMATIONS' },
  { id: 27, type: 'NATURAL', subtype: 'FLORA AND FAUNA', subtype_two: null, tag: 'BIRDS' },
  { id: 28, type: 'NATURAL', subtype: 'FLORA AND FAUNA', subtype_two: null, tag: 'MAMMALS' },
  { id: 29, type: 'NATURAL', subtype: 'FLORA AND FAUNA', subtype_two: null, tag: 'AMPHIBIANS' },
  { id: 30, type: 'NATURAL', subtype: 'FLORA AND FAUNA', subtype_two: null, tag: 'REPTILES' },
  { id: 31, type: 'NATURAL', subtype: 'FLORA AND FAUNA', subtype_two: null, tag: 'FISH' },
  { id: 32, type: 'NATURAL', subtype: 'FLORA AND FAUNA', subtype_two: null, tag: 'MOLLUSCS' },
  { id: 33, type: 'NATURAL', subtype: 'FLORA AND FAUNA', subtype_two: null, tag: 'INSECTS' },
  { id: 34, type: 'NATURAL', subtype: 'EVENT', subtype_two: null, tag: 'ASTRONOMIC' },
  { id: 35, type: 'NATURAL', subtype: 'EVENT', subtype_two: null, tag: 'FLOWERING' },
  { id: 36, type: 'NATURAL', subtype: 'EVENT', subtype_two: null, tag: 'FISH OVIPOSITION' },
  { id: 37, type: 'NATURAL', subtype: 'EVENT', subtype_two: null, tag: 'INSECT METAMORPHOSIS' },
  { id: 38, type: 'NATURAL', subtype: 'EVENT', subtype_two: null, tag: 'ZEAL AND REPRODUCTION OF ANIMALS' },
  { id: 39, type: 'NATURAL', subtype: 'EVENT', subtype_two: null, tag: 'MIGRATION' },
  { id: 40, type: 'CULTURAL', subtype: 'MOVABLE', subtype_two: 'ARCHAEOLOGICAL ELEMENTS', tag: 'ARTEFACTS' },
  {
    id: 41,
    type: 'CULTURAL',
    subtype: 'MOVABLE',
    subtype_two: 'ARCHAEOLOGICAL ELEMENTS',
    tag: 'OBJECTS AND STATUES MADE OF STONE, WOOD, BONE, METAL, CERAMICS'
  },
  { id: 42, type: 'CULTURAL', subtype: 'MOVABLE', subtype_two: 'ARCHAEOLOGICAL ELEMENTS', tag: 'TEXTILE ELEMENTS' },
  { id: 43, type: 'CULTURAL', subtype: 'MOVABLE', subtype_two: 'ARCHAEOLOGICAL ELEMENTS', tag: 'DECORATED PLATELETS' },
  {
    id: 44,
    type: 'CULTURAL',
    subtype: 'MOVABLE',
    subtype_two: 'ARCHAEOLOGICAL ELEMENTS',
    tag: 'HUMAN OR ANIMAL BONES'
  },
  { id: 45, type: 'CULTURAL', subtype: 'MOVABLE', subtype_two: 'ARCHAEOLOGICAL ELEMENTS', tag: 'MUMMIES' },
  { id: 46, type: 'CULTURAL', subtype: 'MOVABLE', subtype_two: 'ARCHAEOLOGICAL ELEMENTS', tag: 'SHIPWRECKS' },
  { id: 47, type: 'CULTURAL', subtype: 'MOVABLE', subtype_two: 'NATURAL ELEMENT', tag: 'PALEONTOLOGICAL REMAINS' },
  { id: 48, type: 'CULTURAL', subtype: 'MOVABLE', subtype_two: 'NATURAL ELEMENT', tag: 'METEORITICS' },
  { id: 49, type: 'CULTURAL', subtype: 'MOVABLE', subtype_two: 'NATURAL ELEMENT', tag: 'MINERALS AND GEMSTONES' },
  {
    id: 50,
    type: 'CULTURAL',
    subtype: 'MOVABLE',
    subtype_two: 'OBJECTS OF ANTHROPOLOGICAL OR ETHNOLOGICAL INTEREST',
    tag: 'RITUAL OBJECTS'
  },
  {
    id: 51,
    type: 'CULTURAL',
    subtype: 'MOVABLE',
    subtype_two: 'OBJECTS OF ANTHROPOLOGICAL OR ETHNOLOGICAL INTEREST',
    tag: 'TOOLS AND DOMESTIC IMPLEMENTS'
  },
  {
    id: 52,
    type: 'CULTURAL',
    subtype: 'MOVABLE',
    subtype_two: 'OBJECTS OF ANTHROPOLOGICAL OR ETHNOLOGICAL INTEREST',
    tag: 'CRAFTWORK'
  },
  { id: 53, type: 'CULTURAL', subtype: 'MOVABLE', subtype_two: 'WORKS OF ART', tag: 'PAINTINGS' },
  { id: 54, type: 'CULTURAL', subtype: 'MOVABLE', subtype_two: 'WORKS OF ART', tag: 'DRAWINGS' },
  { id: 55, type: 'CULTURAL', subtype: 'MOVABLE', subtype_two: 'WORKS OF ART', tag: 'ENGRAVINGS' },
  { id: 56, type: 'CULTURAL', subtype: 'MOVABLE', subtype_two: 'WORKS OF ART', tag: 'SCULPTURES' },
  { id: 57, type: 'CULTURAL', subtype: 'MOVABLE', subtype_two: 'WORKS OF ART', tag: 'GRAPHICAL SERIE WORKS' },
  { id: 58, type: 'CULTURAL', subtype: 'MOVABLE', subtype_two: 'WORKS OF ART', tag: 'WORKS' },
  { id: 59, type: 'CULTURAL', subtype: 'MOVABLE', subtype_two: 'WORKS OF ART', tag: 'DECORATIVE ARTS' },
  { id: 60, type: 'CULTURAL', subtype: 'MOVABLE', subtype_two: 'WORKS OF ART', tag: 'ART INSTALLATIONS' },
  {
    id: 61,
    type: 'CULTURAL',
    subtype: 'MOVABLE',
    subtype_two: 'ANTIQUES WITH A HISTORICAL AND/OR SOCIAL SIGNIFICANCE',
    tag: 'ELEMENTS RELATED TO WORSHIP'
  },
  {
    id: 62,
    type: 'CULTURAL',
    subtype: 'MOVABLE',
    subtype_two: 'ANTIQUES WITH A HISTORICAL AND/OR SOCIAL SIGNIFICANCE',
    tag: 'FUNERARY ELEMENTS'
  },
  {
    id: 63,
    type: 'CULTURAL',
    subtype: 'MOVABLE',
    subtype_two: 'ANTIQUES WITH A HISTORICAL AND/OR SOCIAL SIGNIFICANCE',
    tag: 'EVERYDAY LIFE ELEMENTS'
  },
  {
    id: 64,
    type: 'CULTURAL',
    subtype: 'MOVABLE',
    subtype_two: 'ANTIQUES WITH A HISTORICAL AND/OR SOCIAL SIGNIFICANCE',
    tag: 'MILITARY OBJECTS'
  },
  {
    id: 65,
    type: 'CULTURAL',
    subtype: 'MOVABLE',
    subtype_two: 'ANTIQUES WITH A HISTORICAL AND/OR SOCIAL SIGNIFICANCE',
    tag: 'MUSICAL INSTRUMENTS'
  },
  {
    id: 66,
    type: 'CULTURAL',
    subtype: 'MOVABLE',
    subtype_two: 'ANTIQUES WITH A HISTORICAL AND/OR SOCIAL SIGNIFICANCE',
    tag: 'NUMISMATIC AND PHILATELIC ELEMENTS'
  },
  {
    id: 67,
    type: 'CULTURAL',
    subtype: 'MOVABLE',
    subtype_two: 'ANTIQUES WITH A HISTORICAL AND/OR SOCIAL SIGNIFICANCE',
    tag: 'COMMEMORATIVE WORKS'
  },
  {
    id: 68,
    type: 'CULTURAL',
    subtype: 'MOVABLE',
    subtype_two: 'ELEMENTS RELATED TO TECHNOLOGY AND THE SCIENCES',
    tag: 'UTENSILS'
  },
  {
    id: 69,
    type: 'CULTURAL',
    subtype: 'MOVABLE',
    subtype_two: 'ELEMENTS RELATED TO TECHNOLOGY AND THE SCIENCES',
    tag: 'INSTRUMENTS'
  },
  {
    id: 70,
    type: 'CULTURAL',
    subtype: 'MOVABLE',
    subtype_two: 'ELEMENTS RELATED TO TECHNOLOGY AND THE SCIENCES',
    tag: 'WORK TOOLS'
  },
  {
    id: 71,
    type: 'CULTURAL',
    subtype: 'MOVABLE',
    subtype_two: 'ELEMENTS RELATED TO TECHNOLOGY AND THE SCIENCES',
    tag: 'TECHNOLOGICAL EQUIPMENT'
  },
  {
    id: 72,
    type: 'CULTURAL',
    subtype: 'MOVABLE',
    subtype_two: 'ELEMENTS RELATED TO TECHNOLOGY AND THE SCIENCES',
    tag: 'MACHINES'
  },
  {
    id: 73,
    type: 'CULTURAL',
    subtype: 'MOVABLE',
    subtype_two: 'ELEMENTS RELATED TO TECHNOLOGY AND THE SCIENCES',
    tag: 'VEHICLES'
  },
  { id: 74, type: 'CULTURAL', subtype: 'MOVABLE', subtype_two: 'DOCUMENTARY ELEMENTS', tag: 'BOOKS' },
  { id: 75, type: 'CULTURAL', subtype: 'MOVABLE', subtype_two: 'DOCUMENTARY ELEMENTS', tag: 'SERIAL PUBLICATIONS' },
  { id: 76, type: 'CULTURAL', subtype: 'MOVABLE', subtype_two: 'DOCUMENTARY ELEMENTS', tag: 'ADMINISTRATIVE ARCHIVES' },
  { id: 77, type: 'CULTURAL', subtype: 'MOVABLE', subtype_two: 'DOCUMENTARY ELEMENTS', tag: 'MUSICAL SCORES' },
  {
    id: 78,
    type: 'CULTURAL',
    subtype: 'MOVABLE',
    subtype_two: 'DOCUMENTARY ELEMENTS',
    tag: 'MAPS AND OTHER CARTOGRAPHIC MATERIALS'
  },
  {
    id: 79,
    type: 'CULTURAL',
    subtype: 'MOVABLE',
    subtype_two: 'DOCUMENTARY ELEMENTS',
    tag: 'PHOTOGRAPHS AND NEGATIVES'
  },
  {
    id: 80,
    type: 'CULTURAL',
    subtype: 'MOVABLE',
    subtype_two: 'DOCUMENTARY ELEMENTS',
    tag: 'SOUND MATERIAL AND MUSICAL RECORDINGS'
  },
  {
    id: 81,
    type: 'CULTURAL',
    subtype: 'MOVABLE',
    subtype_two: 'DOCUMENTARY ELEMENTS',
    tag: 'AUDIO-VISUAL PRODUCTIONS'
  },
  { id: 82, type: 'CULTURAL', subtype: 'MOVABLE', subtype_two: 'DOCUMENTARY ELEMENTS', tag: 'ELECTRONIC RESOURCES' },
  {
    id: 83,
    type: 'CULTURAL',
    subtype: 'MOVABLE',
    subtype_two: 'DOCUMENTARY ELEMENTS',
    tag: 'DIGITAL OBJECTS AND MULTIMEDIA CREATIONS'
  },
  {
    id: 84,
    type: 'CULTURAL',
    subtype: 'MOVABLE',
    subtype_two: 'DISMEMBERED ELEMENTS FROM ARTISTIC OR HISTORICAL MONUMENTS OR ARCHAEOLOGICAL SITES',
    tag: 'REMAINS OF STRUCTURES'
  },
  {
    id: 85,
    type: 'CULTURAL',
    subtype: 'MOVABLE',
    subtype_two: 'DISMEMBERED ELEMENTS FROM ARTISTIC OR HISTORICAL MONUMENTS OR ARCHAEOLOGICAL SITES',
    tag: 'ORNAMENTAL ELEMENTS'
  },
  {
    id: 86,
    type: 'CULTURAL',
    subtype: 'MOVABLE',
    subtype_two: 'DISMEMBERED ELEMENTS FROM ARTISTIC OR HISTORICAL MONUMENTS OR ARCHAEOLOGICAL SITES',
    tag: 'FUNCTIONAL ELEMENTS'
  },
  {
    id: 87,
    type: 'CULTURAL',
    subtype: 'IMMOVABLE',
    subtype_two: 'MONUMENTS, BUILDINGS AND HERITAGE SITES',
    tag: 'PUBLIC CIVIL BUILDINGS'
  },
  {
    id: 88,
    type: 'CULTURAL',
    subtype: 'IMMOVABLE',
    subtype_two: 'MONUMENTS, BUILDINGS AND HERITAGE SITES',
    tag: 'RESIDENTIAL BUILDINGS'
  },
  {
    id: 89,
    type: 'CULTURAL',
    subtype: 'IMMOVABLE',
    subtype_two: 'MONUMENTS, BUILDINGS AND HERITAGE SITES',
    tag: 'RELIGIOUS CONSTRUCTIONS'
  },
  {
    id: 90,
    type: 'CULTURAL',
    subtype: 'IMMOVABLE',
    subtype_two: 'MONUMENTS, BUILDINGS AND HERITAGE SITES',
    tag: 'DEFENSE CONSTRUCTIONS'
  },
  {
    id: 91,
    type: 'CULTURAL',
    subtype: 'IMMOVABLE',
    subtype_two: 'MONUMENTS, BUILDINGS AND HERITAGE SITES',
    tag: 'TRADITIONAL RURAL CONSTRUCTIONS OF ETHNOGRAPHIC VALUE'
  },
  {
    id: 92,
    type: 'CULTURAL',
    subtype: 'IMMOVABLE',
    subtype_two: 'MONUMENTS, BUILDINGS AND HERITAGE SITES',
    tag: 'FUNERARY CONSTRUCTIONS'
  },
  {
    id: 93,
    type: 'CULTURAL',
    subtype: 'IMMOVABLE',
    subtype_two: 'MONUMENTS, BUILDINGS AND HERITAGE SITES',
    tag: 'HISTORIC SITES OR PLACES AND COMMEMORATIVE ELEMENTS'
  },
  {
    id: 94,
    type: 'CULTURAL',
    subtype: 'IMMOVABLE',
    subtype_two: 'MONUMENTS, BUILDINGS AND HERITAGE SITES',
    tag: 'MINING AND INDUSTRIAL HERITAGE ELEMENTS'
  },
  {
    id: 95,
    type: 'CULTURAL',
    subtype: 'IMMOVABLE',
    subtype_two: 'ARCHAEOLOGICAL HERITAGE ELEMENTS',
    tag: 'ARCHAEOLOGICAL SITE'
  },
  {
    id: 96,
    type: 'CULTURAL',
    subtype: 'IMMOVABLE',
    subtype_two: 'ARCHAEOLOGICAL HERITAGE ELEMENTS',
    tag: 'ROCKY OR PARIETAL PAINTINGS'
  },
  {
    id: 97,
    type: 'CULTURAL',
    subtype: 'IMMOVABLE',
    subtype_two: 'ARCHAEOLOGICAL HERITAGE ELEMENTS',
    tag: 'ARCHAEOLOGICAL AREA'
  },
  { id: 98, type: 'CULTURAL', subtype: 'IMMOVABLE', subtype_two: 'URBAN ELEMENTS', tag: 'URBAN LAYOUT' },
  { id: 99, type: 'CULTURAL', subtype: 'IMMOVABLE', subtype_two: 'URBAN ELEMENTS', tag: 'URBAN LANDMARK' },
  { id: 100, type: 'CULTURAL', subtype: 'IMMOVABLE', subtype_two: 'URBAN ELEMENTS', tag: 'DISTRICTS' },
  { id: 101, type: 'CULTURAL', subtype: 'IMMOVABLE', subtype_two: 'OTHERS', tag: 'TECHNOLOGICAL CENTRES' },
  { id: 102, type: 'CULTURAL', subtype: 'IMMOVABLE', subtype_two: 'OTHERS', tag: 'ENGINEERING WORKS' },
  { id: 103, type: 'CULTURAL', subtype: 'IMMOVABLE', subtype_two: 'OTHERS', tag: 'MODERN ARCHITECTURE' },
  {
    id: 104,
    type: 'CULTURAL',
    subtype: 'INTANGIBLE',
    subtype_two: 'ARTISTIC AND PERFORMING MANIFESTATION AND EXPRESSION',
    tag: 'LITERATURE'
  },
  {
    id: 105,
    type: 'CULTURAL',
    subtype: 'INTANGIBLE',
    subtype_two: 'ARTISTIC AND PERFORMING MANIFESTATION AND EXPRESSION',
    tag: 'MUSIC'
  },
  {
    id: 106,
    type: 'CULTURAL',
    subtype: 'INTANGIBLE',
    subtype_two: 'ARTISTIC AND PERFORMING MANIFESTATION AND EXPRESSION',
    tag: 'DANCE'
  },
  {
    id: 107,
    type: 'CULTURAL',
    subtype: 'INTANGIBLE',
    subtype_two: 'ARTISTIC AND PERFORMING MANIFESTATION AND EXPRESSION',
    tag: 'THEATRE'
  },
  {
    id: 108,
    type: 'CULTURAL',
    subtype: 'INTANGIBLE',
    subtype_two: 'LUDIC EXPRESSION AND CELEBRATION',
    tag: 'CELEBRATION'
  },
  { id: 109, type: 'CULTURAL', subtype: 'INTANGIBLE', subtype_two: 'LUDIC EXPRESSION AND CELEBRATION', tag: 'SPORT' },
  {
    id: 110,
    type: 'CULTURAL',
    subtype: 'INTANGIBLE',
    subtype_two: 'LUDIC EXPRESSION AND CELEBRATION',
    tag: "CHILDREN'S GAME"
  },
  { id: 111, type: 'CULTURAL', subtype: 'INTANGIBLE', subtype_two: 'ORAL TRADITION AND EXPRESSION', tag: 'LANGUAGE' },
  { id: 112, type: 'CULTURAL', subtype: 'INTANGIBLE', subtype_two: 'ORAL TRADITION AND EXPRESSION', tag: 'DIALECT' },
  { id: 113, type: 'CULTURAL', subtype: 'INTANGIBLE', subtype_two: 'ORAL TRADITION AND EXPRESSION', tag: 'TOPONYM' },
  {
    id: 114,
    type: 'CULTURAL',
    subtype: 'INTANGIBLE',
    subtype_two: 'ORAL TRADITION AND EXPRESSION',
    tag: 'ORAL EXPRESSION'
  },
  {
    id: 115,
    type: 'CULTURAL',
    subtype: 'INTANGIBLE',
    subtype_two: 'ORAL TRADITION AND EXPRESSION',
    tag: 'LOCAL MEMORY LINKED TO HISTORICAL EVENT AND PLACE'
  },
  {
    id: 116,
    type: 'CULTURAL',
    subtype: 'INTANGIBLE',
    subtype_two: 'ORAL TRADITION AND EXPRESSION',
    tag: 'MUSICAL PIECE'
  },
  {
    id: 117,
    type: 'CULTURAL',
    subtype: 'INTANGIBLE',
    subtype_two: 'ORAL TRADITION AND EXPRESSION',
    tag: 'POPULAR ANONYM SONG'
  },
  { id: 118, type: 'CULTURAL', subtype: 'INTANGIBLE', subtype_two: 'SOCIAL USE AND PRACTICE', tag: 'BELIEF' },
  {
    id: 119,
    type: 'CULTURAL',
    subtype: 'INTANGIBLE',
    subtype_two: 'SOCIAL USE AND PRACTICE',
    tag: 'RITUAL AND RELIGIOUS PRACTICE'
  },
  {
    id: 120,
    type: 'CULTURAL',
    subtype: 'INTANGIBLE',
    subtype_two: 'SOCIAL USE AND PRACTICE',
    tag: 'TRADITIONAL AND COMMUNAL PRACTICE'
  },
  {
    id: 121,
    type: 'CULTURAL',
    subtype: 'INTANGIBLE',
    subtype_two: 'SOCIAL USE AND PRACTICE',
    tag: 'PEOPLE\u2019S BEHAVIOURAL PATTERN'
  },
  {
    id: 122,
    type: 'CULTURAL',
    subtype: 'INTANGIBLE',
    subtype_two: 'SOCIAL USE AND PRACTICE',
    tag: 'POPULAR TRADITION'
  },
  { id: 123, type: 'CULTURAL', subtype: 'INTANGIBLE', subtype_two: 'SOCIAL USE AND PRACTICE', tag: 'DIETARY HABIT' },
  { id: 124, type: 'CULTURAL', subtype: 'INTANGIBLE', subtype_two: 'SOCIAL USE AND PRACTICE', tag: 'COOKING METHOD' },
  {
    id: 125,
    type: 'CULTURAL',
    subtype: 'INTANGIBLE',
    subtype_two: 'KNOWLEDGE AND PRACTICES CONCERNING NATURE AND THE UNIVERSE',
    tag: 'TRADITIONAL SCIENTIFIC AND HUMANISTIC KNOWLEDGE'
  },
  {
    id: 126,
    type: 'CULTURAL',
    subtype: 'INTANGIBLE',
    subtype_two: 'KNOWLEDGE AND PRACTICES CONCERNING NATURE AND THE UNIVERSE',
    tag: 'TRADITIONAL ECOLOGICAL KNOWLEDGE'
  },
  {
    id: 127,
    type: 'CULTURAL',
    subtype: 'INTANGIBLE',
    subtype_two: 'KNOWLEDGE AND PRACTICES CONCERNING NATURE AND THE UNIVERSE',
    tag: 'USES AND KNOWLEDGE OF INDIGENOUS PEOPLE'
  },
  {
    id: 128,
    type: 'CULTURAL',
    subtype: 'INTANGIBLE',
    subtype_two: 'KNOWLEDGE AND PRACTICES CONCERNING NATURE AND THE UNIVERSE',
    tag: 'TRADITIONAL HEALTH AND MEDICINE PRACTICES AND KNOWLEDGE'
  },
  {
    id: 129,
    type: 'CULTURAL',
    subtype: 'INTANGIBLE',
    subtype_two: 'KNOWLEDGE ON TRADITIONAL PRODUCTIVE METHODS AND TECHNIQUES',
    tag: 'TRADITIONAL CRAFTSMANSHIP TECHNIQUE'
  },
  {
    id: 130,
    type: 'CULTURAL',
    subtype: 'INTANGIBLE',
    subtype_two: 'KNOWLEDGE ON TRADITIONAL PRODUCTIVE METHODS AND TECHNIQUES',
    tag: 'SETTLEMENT PATTERN AND TRADITIONAL CONSTRUCTION TECHNIQUE'
  },
  {
    id: 131,
    type: 'CULTURAL',
    subtype: 'INTANGIBLE',
    subtype_two: 'KNOWLEDGE ON TRADITIONAL PRODUCTIVE METHODS AND TECHNIQUES',
    tag: 'TRADITIONAL OCCUPATION'
  },
  {
    id: 132,
    type: 'CULTURAL',
    subtype: 'INTANGIBLE',
    subtype_two: 'KNOWLEDGE ON TRADITIONAL PRODUCTIVE METHODS AND TECHNIQUES',
    tag: 'TRADITIONAL PRODUCTIVE TECHNIQUE AND KNOWLEDGE'
  },
  {
    id: 133,
    type: 'CULTURAL',
    subtype: 'INTANGIBLE',
    subtype_two: 'CUSTOMARY GOVERNANCE SYSTEM',
    tag: 'TRADITIONAL INSTITUTION'
  },
  {
    id: 134,
    type: 'CULTURAL',
    subtype: 'INTANGIBLE',
    subtype_two: 'CUSTOMARY GOVERNANCE SYSTEM',
    tag: 'TRADITIONAL RULES OR NORMS'
  },
  {
    id: 135,
    type: 'CULTURAL',
    subtype: 'INTANGIBLE',
    subtype_two: 'CUSTOMARY GOVERNANCE SYSTEM',
    tag: 'CUSTOMARY LEGAL SYSTEM'
  },
  {
    id: 136,
    type: 'CULTURAL',
    subtype: 'INTANGIBLE',
    subtype_two: 'CUSTOMARY GOVERNANCE SYSTEM',
    tag: 'SOCIAL VALUE SYSTEM'
  },
  {
    id: 137,
    type: 'CULTURAL',
    subtype: 'INTANGIBLE',
    subtype_two: 'CUSTOMARY GOVERNANCE SYSTEM',
    tag: 'TRADITIONAL SOCIAL ORGANIZATION'
  },
  { id: 138, type: 'NATURAL', subtype: 'EVENT', subtype_two: null, tag: 'ATMOSPHERIC' },
  { id: 139, type: 'NATURAL', subtype: 'EVENT', subtype_two: null, tag: 'GEOLOGICAL' },
  { id: 140, type: 'MIXED', subtype: 'MIXED', subtype_two: null, tag: 'SACRED NATURAL SITE' },
  { id: 141, type: 'MIXED', subtype: 'MIXED', subtype_two: null, tag: 'LANDSCAPE OF SPIRITUAL SIGNIFICANCE' },
  { id: 142, type: 'MIXED', subtype: 'MIXED', subtype_two: null, tag: 'LANDSCAPE OF AESTHETIC SIGNIFICANCE' },
  { id: 143, type: 'MIXED', subtype: 'MIXED', subtype_two: null, tag: 'AGRICULTURAL LANDSCAPE' },
  { id: 144, type: 'MIXED', subtype: 'MIXED', subtype_two: null, tag: 'ARCHEOLOGICAL SITE' },
  { id: 145, type: 'MIXED', subtype: 'MIXED', subtype_two: null, tag: 'HISTORIC SITE' },
  { id: 146, type: 'MIXED', subtype: 'MIXED', subtype_two: null, tag: 'INDUSTRIAL SITE' },
  { id: 147, type: 'MIXED', subtype: 'MIXED', subtype_two: null, tag: 'PASTORAL LANDSCAPE' },

  { id: 148, type: 'MIXED', subtype: 'MIXED', subtype_two: null, tag: 'GARDEN AND PARKLAND LANDSCAPE' },
  { id: 149, type: 'MIXED', subtype: 'MIXED', subtype_two: null, tag: 'BOTANICAL GARDEN' },
  { id: 150, type: 'MIXED', subtype: 'MIXED', subtype_two: null, tag: 'CEMETERIES' },
  { id: 151, type: 'MIXED', subtype: 'MIXED', subtype_two: null, tag: 'HISTORIC TOWN OR VILLAGE' },

  { id: 152, type: 'MIXED', subtype: 'MIXED', subtype_two: null, tag: 'CULTIVATION PRACTICE' },
  { id: 153, type: 'MIXED', subtype: 'MIXED', subtype_two: null, tag: 'FARMING SYSTEM' },
  { id: 154, type: 'MIXED', subtype: 'MIXED', subtype_two: null, tag: 'PLANTATIONS AND CROPS' },
  { id: 155, type: 'MIXED', subtype: 'MIXED', subtype_two: null, tag: 'ANIMAL HUSBANDRY' },
  { id: 156, type: 'MIXED', subtype: 'MIXED', subtype_two: null, tag: 'TRADITIONAL KNOW-HOW' },
  { id: 157, type: 'MIXED', subtype: 'MIXED', subtype_two: null, tag: 'SUSTAINABLE LAND-USE' },

  { id: 158, type: 'NATURAL', subtype: 'FLORA AND FAUNA', subtype_two: null, tag: 'TREE' },
  { id: 159, type: 'NATURAL', subtype: 'FLORA AND FAUNA', subtype_two: null, tag: 'FLOWER' }
]

export default heritage_tags
