import http from '../http-common'
import { ENV } from '../utils'

class Geographical_scopes {
  async getScope() {
    const { data } = await http.get(`/api/${ENV.API_ROUTES.Geographical_scopes}`)
    return data
  }
}

export const geographical_scopes_service = new Geographical_scopes()
