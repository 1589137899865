import { Modal, Form, Input, message } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './styles.css'
import CaptchaComponent from '../Captcha'
const { TextArea } = Input

const ReportIncidentModal = ({ isVisible, onClose, onSubmit, initialValues }) => {
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const captchaRef = useRef(null)
  const [isVerified, setIsVerified] = useState(false)

  const handleRecaptchaChange = (value) => {
    if (value) {
      setIsVerified(true)
    } else {
      setIsVerified(false)
    }
  }

  useEffect(() => {
    form.setFieldsValue(initialValues)
    if (captchaRef.current) {
      captchaRef.current.reset()
      setIsVerified(false)
    }
  }, [initialValues, form])

  return (
    <Modal
      visible={isVisible}
      title={t('Report incidence')}
      onCancel={onClose}
      footer={[
        <button
          className="reportModalButton"
          key="submit"
          type="primary"
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                if (!isVerified) {
                  message.error(t('Please complete captcha'))
                  return
                }
                form.resetFields()
                onSubmit(values)
                captchaRef.current.reset()
              })
              .catch((info) => {
                console.debug('Validación fallida:', info)
              })
          }}
        >
          {t('Send')}
        </button>
      ]}
    >
      <Form form={form} layout="vertical" initialValues={initialValues}>
        <Form.Item
          label={t('Reported title')}
          name="reported_entity_title"
          rules={[{ required: true, message: 'Por favor, proporciona el título' }]}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label={t('Reported description')}
          name="incident_description"
          rules={[{ required: true, message: 'Por favor, describe la incidencia' }]}
        >
          <TextArea rows={4} placeholder={t('Problem description')} />
        </Form.Item>

        <Form.Item
          label={t('Entity')}
          name="report_entity"
          rules={[{ required: true, message: 'Por favor, proporciona el título' }]}
          hidden={true}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label={t('Reporter email')}
          name="reporter_email"
          rules={[
            { required: true, message: t('Insert valid email') },
            { type: 'email', message: t('Insert valid email') }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="ID de la Entidad" name="id_entity" rules={[{ required: true }]} hidden={true}></Form.Item>
        <CaptchaComponent ref={captchaRef} onChange={handleRecaptchaChange} isVerified={isVerified} />
      </Form>
    </Modal>
  )
}

export default ReportIncidentModal
