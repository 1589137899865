import { Route } from 'react-router-dom'
import UserProfile from '../../../components/UserProfile.component'

export const UserRoutes = () => {
  return (
    <>
      <Route path="/profile" element={<UserProfile value={false} />} />
    </>
  )
}
