import { useEffect, useState } from 'react'
import PaginationTable from '../../../components/PaginationTable'
import { stateService } from '../../../services/state.service'
import { Flex, Spin, message } from 'antd'
import { userMessages } from '../../../utils/userMessages'
import { columns } from './table/columns'
import { extractAndConvertValues } from '../../../utils/helpers'

export default function State() {
  const [tableProps, setTableProps] = useState({
    title: 'States',
    entity: 'state',
    columns: columns,
    actions: ['edit', 'remove', 'info'],
    hasRowSelection: false,
    data: [],
    defaultFilter: {}
  })

  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    total: 0
  })

  const [loading, setLoading] = useState(false)

  const getStates = async (page = 1, pageSize = 10, filters = tableProps.defaultFilter) => {
    const offset = (page - 1) * pageSize
    const limit = pageSize
    let newStates = []
    const filter = extractAndConvertValues(filters)
    try {
      setLoading(true)
      const statesResponse = await stateService.getStates(limit, offset, filter)
      newStates = statesResponse.result

      setPagination((prevState) => ({
        ...prevState,
        currentPage: page,
        pageSize: pageSize,
        total: statesResponse.totalCount
      }))
      setTableProps((prevState) => ({
        ...prevState,
        data: newStates,
        defaultFilter: filters
      }))
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleSearch = async (filters) => {
    getStates(1, 10, filters)
  }

  const deleteState = async (state) => {
    await stateService.deleteStateById(state.id)
    message.success(userMessages.deleted)
    setTableProps((prevState) => ({
      ...prevState,
      data: prevState.data.filter((s) => s.id !== state.id)
    }))
    getStates(pagination.currentPage, pagination.pageSize)
  }

  const renderMobileTable = (columns) => {
    return columns.filter((column) => column.key === 'name')
  }

  const renderTabletTable = (columns) => {
    return columns.filter((column) => column.key === 'name' || column.key === 'creation_date')
  }

  useEffect(() => {
    getStates(pagination.currentPage, pagination.pageSize)

    window.addEventListener('resize', function () {
      resize()
    })
    resize()

    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [])

  const resize = () => {
    if (window.innerWidth <= 650) {
      const cols = renderMobileTable(tableProps.columns)
      setTableProps((prevState) => ({
        ...prevState,
        columns: cols
      }))
    } else if (window.innerWidth > 650 && window.innerWidth < 820) {
      const cols = renderTabletTable(tableProps.columns)
      setTableProps((prevState) => ({
        ...prevState,
        columns: cols
      }))
    } else {
      setTableProps((prevState) => ({
        ...prevState,
        columns: columns
      }))
    }
  }

  return (
    <div className="content">
      {loading ? (
        <Flex align="center" gap="middle" justify="center">
          <Spin size="large" />
        </Flex>
      ) : (
        <PaginationTable
          props={{
            ...tableProps,
            onAdd: () => getStates(pagination.currentPage, pagination.pageSize),
            onUpdate: () => getStates(pagination.currentPage, pagination.pageSize),
            onDelete: deleteState,
            pagination: pagination,
            onPaginationChange: async (page, pageSize) => {
              await getStates(page, pageSize)
            },
            onSearch: handleSearch
          }}
        />
      )}
    </div>
  )
}
