import React from 'react'
import '../../styles/App.css'
import { useFormik } from 'formik'
import { useAuth } from '../../Hooks'
import { Link, useNavigate } from 'react-router-dom'
import { message } from 'antd'
import { userMessages } from '../../utils/userMessages'
import { useTranslation } from 'react-i18next'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { initialValues } from '../../utils/initialValuesAuth'

export default function LoginPage() {
  const { login } = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: initialValues(),
    onSubmit: async (values) => {
      try {
        await login({ email: values.email, pswd: values.password })
        navigate('/')
        message.success(userMessages.loginOK)
      } catch (error) {
        message.error(userMessages.loginFail)
      }
    }
  })

  return (
    <div className="auth-wrapper">
      <div className="auth-inner">
        <form onSubmit={formik.handleSubmit}>
          <h3>{t('Login')}</h3>

          <div className="mb-3">
            <label>{t('Email address')}</label>
            <input
              id="email"
              name="email"
              type="email"
              className="form-control"
              placeholder={t('Email address')}
              onChange={formik.handleChange}
            />
          </div>

          <div className="mb-3">
            <label>{t('Password')}</label>
            <input
              id="password"
              name="password"
              type="password"
              className="form-control"
              placeholder={t('Password')}
              onChange={formik.handleChange}
            />
          </div>

          <div className="mb-3">
            <div className="custom-control custom-checkbox">
              <input type="checkbox" className="custom-control-input" id="customCheck1" />
              <label className="custom-control-label ms-1" htmlFor="customCheck1">
                {t('Rememeber me')}
              </label>
            </div>
            <Link to="/sign-up"> {t('Do you have account')}</Link>
            <br></br>
            <Link to="/recover-password">{t('forgot password')}</Link>
          </div>

          <div className="d-grid">
            <button type="submit" className="btn btn-primary">
              {' '}
              {t('Send')}{' '}
            </button>
            <button className="return-button btn mt-3 " onClick={() => navigate(`/`)}>
              <ArrowLeftOutlined style={{ marginRight: 8 }} />
              {t('Return to map')}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
