import React, { useEffect, useState } from 'react'
import { Button, Col, DatePicker, Form, Input, Typography, Row, Select, theme, Divider, message, Radio } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import dayjs from 'dayjs'
import { Formik, ErrorMessage } from 'formik'
import { useTranslation } from 'react-i18next'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { DeleteOutlined, PlusOutlined, HolderOutlined } from '@ant-design/icons'
import { RoadRoute } from '../../../../models/RoadRoute'
import road_routes_keywords from '../../../../../src/databases/road_routes_keywords'
import heritageService from '../../../../services/heritage.service'
import roadService from '../../../../services/road.service'
import DropdownCountries from '../../../../components/DropdownCountries/DropdownCountries.component'
import { userMessages } from '../../../../utils/userMessages'
import { getSubtypes } from '../../../../utils/helpers'
import { geographical_scopes_service } from '../../../../services/geographical_scopes.service'
import { Checkbox } from 'antd'
import { draftService } from '../../../../services/draft.service'

const { useToken } = theme
const { Text } = Typography

const RoadRouteForm = (props) => {
  const { element, onAdd, onUpdate, isModalOpen } = props
  const { t } = useTranslation()
  const { token } = useToken()

  //const roadRoute = element ? { ...element, actions: null } : new RoadRoute()
  const [roadRoute, setRoadRoute] = useState(element ? { ...element, actions: null } : new RoadRoute())
  const [heritageForm, setHeritageForm] = useState('')
  const [interestLink, setInterestLink] = useState({
    name: '',
    url: ''
  })
  const [draft, setDraft] = useState()
  const [isAlertVisible, setIsAlertVisible] = useState(false)
  const [heritageList, setHeritageList] = useState([])
  const [heritageIdList, setHeritageIdList] = useState([])
  const [interestLinksList, setInterestLinksList] = useState([])
  const [geographical_scopes, setGeographical_scopes] = useState([])
  const [heritageFieldvalues, setHeritageFieldValues] = useState({ heritage_field_id: '1-1' })
  const [heritageFields, setHeritageFields] = useState([])
  const [heritageSubtypes, setHeritageSubtypes] = useState([])
  const [heritageTags, setHeritageTags] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const [selectedKeywords, setSelectedKeywords] = useState([])
  const [selectedSubtype, setSelectedSubtype] = useState()
  const [openRoad, setOpenRoad] = useState(element?.tags?.length > 0 ? true : false)

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const setFieldsData = (field_id = 1, type_id = 1, subtype_id, tags, heritageFieldsList = heritageFields) => {
    setHeritageFieldValues({ heritage_field_id: `${field_id}-${type_id}` })
    const Subtypes = getSubtypes(field_id, type_id, heritageFieldsList)
    const filteredSubtypes = Subtypes.map((element) => ({ value: element.id, label: element.name }))
    setHeritageSubtypes(filteredSubtypes)
    if (subtype_id) {
      const selectedSubtype = filteredSubtypes.find((element) => element.value == subtype_id)
      const selectedSubtypeTags = Subtypes.find((element) => element.id == subtype_id)?.tags
      const filteredTagsList = selectedSubtypeTags?.map((element) => ({ value: element.id, label: element.name }))
      setHeritageTags(filteredTagsList)
      setSelectedTags(tags)
      setSelectedSubtype(selectedSubtype)
      form.setFieldsValue({ subtype: selectedSubtype })
      form.setFieldsValue({ tags: tags })
    }
  }

  const validate = (values) => {
    const errors = {}
    if (!values.country_id) {
      errors.country_id = 'Required'
    }
    if (!values.name) {
      errors.name = 'Required'
    }
    if (!values.description) {
      errors.description = 'Required'
    }
    if (!values.description_local) {
      errors.description_local = 'Required'
    }
    if (!values.date) {
      errors.date = 'Required'
    }
    if (!values.geographical_scope_id) {
      errors.geographical_scope_id = 'Required'
    }
    if (!values.country_id) {
      errors.country_id = 'Required'
    }

    return errors
  }

  const getGeographical_scopes = async () => {
    const result = await geographical_scopes_service.getScope()
    const options = result?.map((item) => {
      return {
        value: item.id,
        label: item.name
      }
    })
    setGeographical_scopes(options)
  }

  // Handle Heritages Links
  const getHeritages = async () => {
    const list = await heritageService.getAllHeritages()

    const options = list?.result?.map((item) => {
      return {
        value: item.id,
        label: item.name
      }
    })

    setHeritageList(options)
  }

  const addHeritage = () => {
    if (heritageForm) {
      const newList = [...heritageIdList, heritageForm]
      setHeritageIdList(newList.filter((value, index) => newList.indexOf(value) === index))
      resetHeritageForm()
    }
  }

  const resetHeritageForm = () => {
    setHeritageForm(undefined)
  }

  const handleChangeHeritageElement = (value, index) => {
    const list = [...heritageIdList]
    list[index] = value
    setHeritageIdList(list)
  }

  const handleRemoveHeritageElement = (index) => {
    let array = [...heritageIdList]
    array.splice(index, 1)
    setHeritageIdList(array)
  }

  // Handle Interest Links
  const addInterestLink = () => {
    setInterestLinksList((prevState) => [...prevState, { ...interestLink }])
    resetInterestLinkForm()
  }

  const resetInterestLinkForm = () => {
    setInterestLink({ name: '', url: '' })
  }

  const removeInterestLinkElement = (index) => {
    let array = [...interestLinksList]
    array.splice(index, 1)
    setInterestLinksList(array)
  }

  const handleChangeInterestLinkItem = (key, index, value) => {
    const newList = [...interestLinksList]
    newList[index][key] = value
    setInterestLinksList(newList)
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result) => {
    if (!result.destination) return

    const movedItems = reorder(heritageIdList, result.source.index, result.destination.index)
    setHeritageIdList(movedItems)
  }

  useEffect(() => {
    if (isModalOpen) {
      getHeritages()
      getGeographical_scopes()
      if (roadRoute.heritages?.length > 0) {
        const list = roadRoute.heritages
        setHeritageIdList(list.map((item) => Number(item)))
      }
      if (roadRoute?.links?.length > 0) {
        setInterestLinksList(roadRoute.links)
      }
    }
    if (element) {
      setSelectedKeywords(element?.keywords)
      form.setFieldsValue({ keywords: element?.keywords, geographical_scope_id: element?.geographical_scope_id })
      element?.tags &&
        heritageService.getTagSubTypeFieldByID(element?.tags[0]).then((response) => {
          setFieldsData(response?.field_id, response.type_id, response.subtype_id, element?.tags, heritageFields)
        })
    }
    if (!element) {
      setInterestLinksList([])
      if (!draft?.id) {
        draftService.getDraft('route').then((draft) => setDraft(draft))
      }
      return
    }
  }, [element, isModalOpen, heritageFields])

  const [form] = Form.useForm()

  useEffect(() => {
    heritageService.getAllHeritageFieldsWithTypeSubTypeTags().then((fieldsArr) => {
      setHeritageFields(fieldsArr)
      // eslint-disable-next-line no-sparse-arrays
      setFieldsData(...[, , , , fieldsArr])
    })
  }, [])

  const onFieldChange = (e) => {
    setSelectedSubtype()
    setSelectedTags([])
    setHeritageTags([])
    setHeritageFieldValues({ heritage_field_id: '1-1' })
    setOpenRoad(e.target.checked)
  }

  const saveDraft = async (values) => {
    try {
      message.success(userMessages.draftSaved)

      if (openRoad) {
        // Handle tags
        if (Array.isArray(values.tags)) {
          values.tags = JSON.stringify(values.tags) // Convert to JSON string if it's an array
        }
      } else {
        values.tags = null // Set tags to null if 'openRoad' is false
      }

      values.links = interestLinksList // Set links from state

      if (Array.isArray(heritageIdList)) {
        // Handle heritageIdList (ensure it's serialized if it's an array)
        values.heritages = JSON.stringify(heritageIdList) // Convert to JSON string
      } else {
        values.heritages = heritageIdList // Use heritageIdList directly if it's not an array
      }

      if (Array.isArray(values.keywords)) {
        // Handle keywords
        values.keywords = JSON.stringify(values.keywords) // Convert to JSON string if it's an array
      }

      await draftService.saveDraft('route', {
        // Save the draft using the service
        ...values // Spread the modified values into the draft data
      })
    } catch (error) {
      console.error('Error saving draft:', error)
      message.error('Failed to save draft.')
    }
  }

  const removeDraft = async () => {
    try {
      // Assuming 'route' is a fixed parameter, otherwise pass it dynamically
      await draftService.removeDraft('route')
      message.success(userMessages.draftRemoved)
      setDraft(null) // Clear the draft after removal
    } catch (error) {
      console.error('Error removing draft:', error)
      message.error('Failed to remove draft.')
    }
  }

  const restoreDraft = async () => {
    try {
      // Helper function to safely convert matched numbers
      const convertToNumbers = (str) => {
        return str ? str.match(/\d+/g)?.map(Number) || [] : []
      }

      const list = convertToNumbers(draft?.value?.heritages)
      setHeritageIdList(list)
      setInterestLinksList(draft?.value?.links)
      setSelectedKeywords(draft?.value?.keywords)

      if (draft?.value?.tags) {
        const tagIds = convertToNumbers(draft.value.tags)
        const tagIdToFetch = tagIds[0]

        const response = await heritageService.getTagSubTypeFieldByID(tagIdToFetch)
        setFieldsData(response?.field_id, response?.type_id, response?.subtype_id, tagIds, heritageFields)
        setOpenRoad(true)
      }

      const formValues = {
        keywords: convertToNumbers(draft?.value?.keywords),
        tags: convertToNumbers(draft?.value?.tags),
        description: draft?.value?.description || '',
        description_local: draft?.value?.description_local || ''
      }

      form.setFieldsValue(formValues)
      setRoadRoute(draft?.value)
      message.success(userMessages.draftRestored)

      // Now remove the draft by its ID after it's restored
      if (draft?.id) {
        await removeDraft(draft.id) // Remove the draft by its ID
      }

      setDraft(null) // Clear the draft after restoring
    } catch (error) {
      console.error('Error restoring draft:', error)
      message.error('Failed to restore draft.')
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...roadRoute }}
      validate={validate}
      validateOnChange={false}
      onSubmit={async (values, actions) => {
        if (openRoad) {
          if (Array.isArray(values.tags)) {
            values.tags = JSON.stringify(values.tags)
          }
        } else {
          values.tags = null
        }
        values.links = interestLinksList
        if (Array.isArray(heritageIdList)) {
          values.heritages = JSON.stringify(heritageIdList)
        } else {
          values.heritages = heritageIdList
        }
        if (Array.isArray(values.keywords)) {
          values.keywords = JSON.stringify(values.keywords)
        }
        try {
          if (element) {
            await roadService.updateRoadRouteById(roadRoute.id, values)
            onUpdate?.()
            // message.success(userMessages.updated);
          } else {
            await roadService.createRoadRoute(values)
            // message.success(userMessages.created);
            onAdd?.()
          }
          setIsAlertVisible(true)
          actions.setSubmitting(false)
        } catch (error) {
          message.error(`${userMessages.error} ${error.message}`)
        }
      }}
    >
      {({ errors, touched, values, handleSubmit, handleChange, setFieldValue }) => (
        <Form
          style={{
            maxWidth: 900
          }}
          form={form}
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
        >
          {draft && (
            <div className="alert alert-light d-flex align-items-center" role="alert">
              <p className="flex-grow-1 m-0">You have a draft saved. Do you want to restore it?</p>
              <div className="d-flex gap-3">
                <button className="btn btn-light btn-sm" type="button" onClick={removeDraft}>
                  Remove
                </button>
                <button className="btn btn-light btn-sm" type="button" onClick={restoreDraft}>
                  Restore
                </button>
              </div>
            </div>
          )}
          <Divider orientation="left" className={'form-divider'}>
            Route Information
          </Divider>

          <Row gutter={24}>
            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Date')}
                name="date"
                className={{ 'input-error': errors.date && touched.date }}
              >
                <>
                  <DatePicker
                    name="date"
                    id="date"
                    style={{ width: '100%' }}
                    value={dayjs(values.date)}
                    onChange={(value) => setFieldValue('date', value)}
                  />
                  <ErrorMessage name="date" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Name')}
                name="name"
                className={{ 'input-error': errors.name && touched.name }}
              >
                <>
                  <Input name="name" id="name" onChange={handleChange} value={values.name} />
                  <ErrorMessage name="name" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item label={t('Email')} name="email" className={{ 'input-error': errors.email && touched.email }}>
                <>
                  <Input name="email" id="email" onChange={handleChange} value={values.email} />
                  <ErrorMessage name="email" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item className={{ 'input-error': errors.country_id && touched.country_id }}>
                <>
                  <DropdownCountries
                    errors={errors}
                    touched={touched}
                    value={values.country_id}
                    onChange={(value) => setFieldValue('country_id', value)}
                  />
                </>
                <ErrorMessage name="country_id" />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label={t('Organizational or Personal Information')}
                name="org_pers_information"
                className={{
                  'input-error': errors.org_pers_information && touched.org_pers_information
                }}
              >
                <>
                  <Input
                    name="org_pers_information"
                    id="org_pers_information"
                    onChange={handleChange}
                    value={values.org_pers_information}
                  />
                  <ErrorMessage name="org_pers_information" />
                </>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Geographical Scope')}
                name="geographical_scope_id"
                className={{ 'input-error': errors.geographical_scope_id && touched.geographical_scope_id }}
              >
                <>
                  <Select
                    name="geographical_scope_id"
                    id="geographical_scope_id"
                    style={{
                      width: '100%'
                    }}
                    placeholder="Choose a Scope"
                    options={geographical_scopes}
                    value={values.geographical_scope_id}
                    onChange={(value) => setFieldValue('geographical_scope_id', value)}
                    showSearch
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  />
                  <ErrorMessage name="geographical_scope_id" />
                </>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('Keywords')}
                name="keywords"
                className={{ 'input-error': errors.keywords && touched.keywords }}
              >
                <>
                  {road_routes_keywords.length > 0 && (
                    <Select
                      name="keywords"
                      id="keywordss"
                      mode="multiple"
                      allowClear
                      style={{
                        width: '100%'
                      }}
                      defaultValue={[]}
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      placeholder="Select keywords"
                      options={road_routes_keywords}
                      value={values.keywords ?? selectedKeywords}
                      onChange={(value) => {
                        setSelectedKeywords(value), setFieldValue('keywords', value)
                      }}
                    />
                  )}
                </>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Divider orientation="left" className={'form-divider'}>
                <Checkbox onChange={onFieldChange} checked={openRoad}>
                  Heritage Group
                </Checkbox>
              </Divider>
            </Col>
            {openRoad && (
              <>
                <Col span={24}>
                  <Form.Item className={{ 'input-error': errors.heritage_field_id && touched.heritage_field_id }}>
                    <Radio.Group
                      className="radio-group-container"
                      name="heritage_field_id"
                      id="heritage_field_id"
                      onChange={(e) => {
                        const [fieldId, typeId] = e.target.value.split('-')
                        setHeritageFieldValues({ heritage_field_id: e.target.value }) // Update state
                        const Subtypes = getSubtypes(fieldId, typeId, heritageFields)
                        const filteredSubtypes = Subtypes.map((element) => ({ value: element.id, label: element.name }))
                        setHeritageSubtypes(filteredSubtypes)
                        setHeritageTags([])
                        form.setFieldsValue({ tags: [] })
                        form.setFieldsValue({ subtype: null })
                        setSelectedTags([])
                        setSelectedSubtype(null)
                      }}
                      value={heritageFieldvalues.heritage_field_id}
                    >
                      <p>{JSON.stringify(selectedTags)}</p>
                      {heritageFields.map((field) => (
                        <div className="radio-container" key={field.id}>
                          <h5>{field.name}</h5>
                          {field.types.map((type) => (
                            <div key={type.id}>
                              <Radio value={`${field.id}-${type.id}`}>{type.name}</Radio>
                            </div>
                          ))}
                        </div>
                      ))}
                    </Radio.Group>

                    <ErrorMessage name="heritage_field_id" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={t('Subtype')}
                    name="subtype"
                    className={{ 'input-error': errors.subtype && touched.subtype }}
                  >
                    <Select
                      name="subtype"
                      id="subtype"
                      style={{
                        width: '100%'
                      }}
                      placeholder="Select a subtype..."
                      options={heritageSubtypes}
                      value={selectedSubtype}
                      onChange={(selectedValue) => {
                        try {
                          form.setFieldsValue({ tags: [] })
                          setSelectedTags([])
                          const selectedSubtype = heritageSubtypes.find((element) => element.value == selectedValue)
                          setSelectedSubtype(selectedSubtype)
                          setFieldValue('subtype', selectedValue)
                          const [fieldId, typeId] = heritageFieldvalues.heritage_field_id.split('-')
                          let filteredSubtypes = getSubtypes(fieldId, typeId, heritageFields)

                          const tagsList = filteredSubtypes.find((element) => element.id == selectedValue)?.tags

                          const filteredTagsList = tagsList.map((element) => ({
                            value: element.id,
                            label: element.name
                          }))
                          setHeritageTags(filteredTagsList)
                        } catch (error) {
                          console.log('Error', error)
                        }
                      }}
                    />
                    <ErrorMessage name="subtype" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label={t('Tags')} name="tags" className={{ 'input-error': errors.tags && touched.tags }}>
                    {heritageTags?.length > 0 && (
                      <Select
                        name="tags"
                        id="tags"
                        mode="multiple"
                        style={{
                          width: '100%'
                        }}
                        placeholder="Select Tags"
                        options={heritageTags}
                        value={
                          selectedTags.length > 0
                            ? selectedTags.map(
                                (tag) => heritageTags.find((t) => t.value == tag || t.label == tag)?.value
                              )
                            : []
                        }
                        onChange={(value) => {
                          setSelectedTags(value)
                          setFieldValue('tags', value)
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
              </>
            )}

            <Col xs={24}>
              <Form.Item
                required
                label={t('Description (English)')}
                name="description"
                className={{ 'input-error': errors.description && touched.description }}
              >
                <>
                  <TextArea
                    name="description"
                    id="description"
                    showCount
                    maxLength={3500}
                    style={{ height: '100%', resize: 'none' }}
                    onChange={handleChange}
                    defaultValue={values.description}
                    value={values.description}
                  />
                  <ErrorMessage name="description" />
                </>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                required
                label={t('Description (Local Language)')}
                name="description_local"
                className={{ 'input-error': errors.description_local && touched.description_local }}
              >
                <>
                  <TextArea
                    name="description_local"
                    id="description_local"
                    showCount
                    maxLength={3500}
                    style={{ height: '100%', resize: 'none' }}
                    onChange={handleChange}
                    defaultValue={values.description_local}
                    value={values.description_local}
                  />
                  <ErrorMessage name="description_local" />
                </>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Divider orientation="left" className={'form-divider'}>
                Heritages
              </Divider>
            </Col>
            <Col xs={24} md={22}>
              <Form.Item label={t('Choose a Heritage')}>
                <Select
                  showSearch
                  style={{
                    width: '100%'
                  }}
                  placeholder="Choose a Heritage"
                  options={heritageList}
                  optionFilterProp="children"
                  onSearch={() => {}}
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  value={heritageForm}
                  onChange={(value) => {
                    setHeritageForm(value)
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={2} style={{ display: 'flex', alignItems: 'end' }}>
              <Form.Item>
                <Button className="icon-button" onClick={() => addHeritage()}>
                  <PlusOutlined style={{ fontSize: 'large', color: token.colorPrimary }} />
                </Button>
              </Form.Item>
            </Col>

            <Col span={24}>
              <div className="links-container">
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable-element">
                    {(provided) => (
                      <div className="links-container-content" {...provided.droppableProps} ref={provided.innerRef}>
                        {heritageIdList.map((heritage, index) => {
                          return (
                            <Draggable key={index} draggableId={`elem-${index}`} index={index}>
                              {(prov, snapshot) => {
                                return (
                                  <div
                                    className={`link-element ${snapshot.isDragging && 'draggin'}`}
                                    ref={prov.innerRef}
                                    {...prov.draggableProps}
                                  >
                                    <HolderOutlined
                                      style={{ fontSize: 'large', padding: '10px' }}
                                      {...prov.dragHandleProps}
                                    />
                                    <div className="w-100">
                                      <small>Heritage Name</small>
                                      <Select
                                        showSearch
                                        style={{
                                          width: '100%'
                                        }}
                                        placeholder="Choose a Heritage"
                                        options={heritageList}
                                        value={heritage}
                                        onChange={(value) => handleChangeHeritageElement(value, index)}
                                        optionFilterProp="children"
                                        onSearch={() => {}}
                                        filterOption={(input, option) =>
                                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                      />
                                    </div>
                                    <div className="ms-3">
                                      <Button
                                        className="icon-button"
                                        onClick={() => handleRemoveHeritageElement(index)}
                                      >
                                        <DeleteOutlined style={{ fontSize: 'large', color: token.colorError }} />
                                      </Button>
                                    </div>
                                  </div>
                                )
                              }}
                            </Draggable>
                          )
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </Col>

            <Col xs={24}>
              <Divider orientation="left" className={'form-divider'}>
                Links of Interest
              </Divider>
            </Col>
            <Col xs={24} md={11}>
              <Form.Item label={t('Name')}>
                <Input
                  value={interestLink.name}
                  onChange={(e) => setInterestLink((prevState) => ({ ...prevState, name: e.target.value }))}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={11}>
              <Form.Item label={t('URL')}>
                <Input
                  value={interestLink.url}
                  onChange={(e) => setInterestLink((prevState) => ({ ...prevState, url: e.target.value }))}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={2} style={{ display: 'flex', alignItems: 'end' }}>
              <Form.Item>
                <Button className="icon-button" onClick={() => addInterestLink()}>
                  <PlusOutlined style={{ fontSize: 'large', color: token.colorPrimary }} />
                </Button>
              </Form.Item>
            </Col>

            <Col span={24}>
              <div className="links-container">
                {interestLinksList.map((link, index) => {
                  return (
                    <div className="link-element" key={link.name}>
                      <div className="w-100">
                        <small>Name</small>
                        <Input
                          value={link.name}
                          onChange={(e) => handleChangeInterestLinkItem('name', index, e.target.value)}
                        />
                      </div>
                      <div className="w-100 ms-3">
                        <small>Url</small>
                        <Input
                          value={link.url}
                          onChange={(e) => handleChangeInterestLinkItem('url', index, e.target.value)}
                        />
                      </div>
                      <div className="ms-3">
                        <Button className="icon-button" onClick={() => removeInterestLinkElement(index)}>
                          <DeleteOutlined style={{ fontSize: 'large', color: token.colorError }} />
                        </Button>
                      </div>
                    </div>
                  )
                })}
              </div>
            </Col>

            <Col span={24}>
              {' '}
              <hr />{' '}
            </Col>

            <Col span={24}>
              <div className="d-flex gap-4">
                {localStorage.getItem('user') && (
                  <Button style={{ width: '50%' }} type="primary" htmlType="button" onClick={() => saveDraft(values)}>
                    Save For Later
                  </Button>
                )}
                <Button style={{ width: '50%' }} type="primary" htmlType="submit">
                  Publish
                </Button>
              </div>
            </Col>
            {isAlertVisible && (
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16
                }}
              >
                <Text type="success">Road Route saved!</Text>
              </Form.Item>
            )}
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default RoadRouteForm
