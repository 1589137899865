import { CalendarOutlined } from '@ant-design/icons'
import { theme } from 'antd'
import { useNavigate } from 'react-router-dom'

const { useToken } = theme

function CalendarButton({ element }) {
  const navigate = useNavigate()
  const { token } = useToken()

  function openCalendar() {
    // Get the door calendar history
    navigate(`/home/door/calendar/${element.id}`)
  }

  return <CalendarOutlined style={{ fontSize: 'large', color: token.colorPrimary }} onClick={openCalendar} />
}

export default CalendarButton
