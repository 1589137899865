import React from 'react'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './styles/App.css'
import 'leaflet/dist/leaflet.css'
import 'leaflet-geosearch/dist/geosearch.css'
import './i18n'

import { AuthProvider } from './context/index'
import DashboardRouter from './Router/DashboardRouter/DashboardRouter.component'

function App() {
  return (
    <AuthProvider>
      <div className="App">
        <DashboardRouter />
      </div>
    </AuthProvider>
  )
}

export default App
