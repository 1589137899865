import React, { useState } from 'react'

import { theme } from 'antd'
import { LinkOutlined } from '@ant-design/icons'
import Modal from 'antd/es/modal/Modal'

import UpdateItemForm from '../../UpdateItemForm'
import { useTranslation } from 'react-i18next'
import UserForm from '../../../pages/Users/components/userForm.component'
import AmbassadorForm from '../../../pages/Ambassadors/components/ambassadorForm.component'
import StateForm from '../../../pages/Cities/States/components/stateForm.component'
import OcityForm from '../../../pages/Cities/Ocity/components/ocityForm.component'
import CitysForm from '../../../pages/Cities/Cities/components/cityForm.component'

const { useToken } = theme
const ContentButton = ({ inputAttributes, element, entity }) => {
  const { token } = useToken()
  const { t } = useTranslation()

  const [isEditModalOpen, setIsEditModalOpen] = useState(false)

  function openEditModal() {
    setIsEditModalOpen(true)
  }

  function handleEditCancel() {
    setIsEditModalOpen(false)
  }

  function renderForm(entity) {
    let form
    switch (entity) {
      case 'user': {
        form = <UserForm element={element} isModalOpen={isEditModalOpen} />
        break
      }

      case 'ocity': {
        form = <OcityForm element={element} isModalOpen={isEditModalOpen} />
        break
      }

      case 'city': {
        form = <CitysForm element={element} isModalOpen={isEditModalOpen} />
        break
      }

      case 'state': {
        form = <StateForm element={element} isModalOpen={isEditModalOpen} />
        break
      }

      case 'ambassador': {
        form = <AmbassadorForm element={element} isModalOpen={isEditModalOpen} />
        break
      }

      default: {
        form = <UpdateItemForm inputAttributes={inputAttributes} element={element} />
        break
      }
    }

    return form
  }

  return (
    <>
      <LinkOutlined style={{ fontSize: 'large', color: token.colorPrimary }} onClick={openEditModal} />
      <Modal
        width={900}
        footer={null}
        title={t(`Edit ${entity ? entity : 'element'}`)}
        open={isEditModalOpen}
        onCancel={handleEditCancel}
      >
        {renderForm(entity)}
      </Modal>
    </>
  )
}

export default ContentButton
