import { useEffect, useState } from 'react'
import roadService from '../../../services/road.service'
import { columns } from './table/columns'
import { Flex, Spin, message } from 'antd'
import { userMessages } from '../../../utils/userMessages'
import PaginationTable from '../../../components/PaginationTable'
import { extractAndConvertValues } from '../../../utils/helpers'

export default function RoadRoute() {
  const [tableProps, setTableProps] = useState({
    title: 'Road Route',
    entity: 'road route',
    columns: columns,
    actions: ['edit', 'remove'],
    hasRowSelection: false,
    defaultFilter: {},
    data: []
  })

  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    total: 0
  })

  const [loading, setLoading] = useState(false)

  const getRoadRoutes = async (page = 1, pageSize = 10, filters = tableProps.defaultFilter) => {
    const offset = (page - 1) * pageSize
    const limit = pageSize
    const filter = extractAndConvertValues(filters)
    let roadRoutesList = []
    try {
      setLoading(true)
      const roadRoutes = await roadService.getAllRoadRoutes(limit, offset, filter)
      roadRoutesList = roadRoutes?.result?.map((item) => {
        return {
          ...item,
          geographical_scope: item.geographicalScope.name
        }
      })
      setPagination((prevState) => ({
        ...prevState,
        currentPage: page,
        pageSize: pageSize,
        total: roadRoutes.totalCount
      }))
      setTableProps((prevState) => ({
        ...prevState,
        data: roadRoutesList,
        defaultFilter: filters
      }))
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleSearch = async (searchTerm) => {
    getRoadRoutes(1, 10, searchTerm)
  }

  const renderMobileTable = (columns) => {
    return columns.filter((column) => column.key === 'name')
  }

  const renderTabletTable = (columns) => {
    return columns.filter((column) => column.key === 'name' || columns.key === 'org_pers_information')
  }

  const deleteRoadRoute = async (road_route) => {
    await roadService.deleteRoadRouteById(road_route.id)
    message.success(userMessages.deleted)
    getRoadRoutes()
  }

  const resize = () => {
    if (window.innerWidth <= 650) {
      const cols = renderMobileTable(tableProps.columns)
      setTableProps((prevState) => ({
        ...prevState,
        columns: cols
      }))
    } else if (window.innerWidth > 650 && window.innerWidth < 820) {
      const cols = renderTabletTable(tableProps.columns)
      setTableProps((prevState) => ({
        ...prevState,
        columns: cols
      }))
    } else {
      setTableProps((prevState) => ({
        ...prevState,
        columns: columns
      }))
    }
  }

  useEffect(() => {
    getRoadRoutes()

    window.addEventListener('resize', function () {
      resize()
    })
    resize()
  }, [])

  return (
    <div className="content">
      {loading ? (
        <Flex align="center" gap="middle" justify="center">
          <Spin size="large" />
        </Flex>
      ) : (
        <PaginationTable
          props={{
            ...tableProps,
            onAdd: () => getRoadRoutes(pagination.currentPage, pagination.pageSize),
            onUpdate: () => getRoadRoutes(pagination.currentPage, pagination.pageSize),
            onDelete: deleteRoadRoute,
            pagination: pagination,
            onPaginationChange: async (page, pageSize) => {
              await getRoadRoutes(page, pageSize)
            },
            onSearch: handleSearch
          }}
        />
      )}
    </div>
  )
}
