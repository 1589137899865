import { useState } from 'react'
import './style.css'
export function CookiePolicy() {
  const [accepted, setAccepted] = useState(false)

  function handleAccept() {
    setAccepted(true)
    localStorage.setItem('cookieAccepted', true)
  }

  if (!accepted && !localStorage.getItem('cookieAccepted')) {
    return (
      <div className="cookie-policy">
        <p>
          Utilizamos cookies para mejorar su experiencia de navegación. Al continuar navegando, acepta nuestra política
          de cookies.
        </p>
        <button onClick={handleAccept}>Aceptar</button>
      </div>
    )
  }
}
