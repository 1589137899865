import L from 'leaflet'
import { useEffect } from 'react'

export const CustomEarthControl = ({ map, defaultZoom = 3, defaultCenter = [51.0, 19.0] }) => {
  useEffect(() => {
    if (!map) return

    const EarthControl = L.Control.extend({
      onAdd: function (map) {
        const zoomControl = document.querySelector('.leaflet-control-container')
        const container = L.DomUtil.create('div', 'leaflet-control-zoom leaflet-bar leaflet-control')
        const button = L.DomUtil.create('a', 'leaflet-control-zoom-earth', container)

        button.innerHTML = '🌍'
        button.title = 'Reset to default view'
        button.href = '#'
        button.style.fontSize = '18px'
        button.style.fontWeight = 'bold'
        button.style.lineHeight = '26px'
        button.style.textAlign = 'center'
        button.style.width = '26px'
        button.style.height = '26px'
        button.style.filter = 'grayscale(1)'

        L.DomEvent.on(button, 'click', function (e) {
          L.DomEvent.stopPropagation(e)
          L.DomEvent.preventDefault(e)
          map.setView(defaultCenter, defaultZoom)
        })

        if (zoomControl) {
          zoomControl.appendChild(container)
        }

        return container
      }
    })

    const earthControl = new EarthControl({ position: 'topleft' }).addTo(map)

    return () => {
      map.removeControl(earthControl)
    }
  }, [map, defaultZoom, defaultCenter])

  return null
}
