export class Heritage {
  constructor() {
    this.id = 0
    this.date = new Date()
    this.organization = ''
    this.email = ''
    this.country_id = null
    this.state_id = null
    this.city_id = null
    this.name = ''
    this.short_heritage_description = ''
    this.extended_heritage_description = ''
    this.short_heritage_description_local = ''
    this.extended_heritage_description_local = ''
    this.heritage_field_id = ''
    this.periodicity = ''
    this.ownership = ''
    this.image = ''
    this.image_copyright = ''
    this.subtype = ''
    this.tags = []
    this.is_museum = false
    this.is_protected = false
    this.is_verified = false
    this.is_verified_text = 'NO'
    this.latitude = '0'
    this.longitude = '0'
    this.has_photos = false
    this.has_audios = false
    this.has_videos = false
    this.has_maps = false
    this.has_bibliography = false
    this.bibliography = []
    this.links = []
    this.protected_values = []
  }
}
