import { Breadcrumb } from 'antd'
import { createContext, useState } from 'react'
import { Link } from 'react-router-dom'

export const BreadcrumbsContext = createContext()
export const BreadcrumbsUpdateContext = createContext(() => {})

const breadcrumbNameMap = {
  '/home': 'Home',

  '/home/LoraWAN': 'LoraWAN',
  '/home/LoraWAN/networkserver': 'Network Servers',
  '/home/LoraWAN/sensor': 'Sensors',
  '/home/LoraWAN/dashboard': 'Dashboard',
  '/home/LoraWAN/message': 'Message',

  '/home/decoder': 'Decoder',
  '/home/decoder/server': 'Server',

  '/home/gateway': 'Gateway',
  '/home/gateway/units': 'Units',
  '/home/gateway/group': 'Group',

  '/home/sensor': 'Sensor',
  '/home/sensor/units': 'Units',
  '/home/sensor/types': 'Types',

  '/home/server': 'Server',

  '/home/project': 'Project',
  '/home/project/business': 'Business',
  '/home/project/projects': 'Projects',
  '/home/project/council': 'Council',

  '/home/user': 'User',

  '/home/logger': 'Logger',

  '/home/door': 'Door',
  '/home/door/doors': 'Doors',
  '/home/door/calendar': 'Calendar',
  '/home/door/request': 'Request',
  '/home/door/user': 'User',

  '/home/energy': 'Energy',
  '/home/energy/groups': 'Groups',
  '/home/energy/devices': 'Devices',
  '/home/energy/alarm': 'Alarm',
  '/home/energy/status': 'Status',
  '/home/energy/planning': 'Planning',
  '/home/energy/actuator': 'Actuator',
  '/home/energy/history': 'History',
  '/home/energy/control': 'Control',

  '/home/water': 'Water',
  '/home/water/general': 'General',
  '/home/water/device': 'Devices',
  '/home/water/group': 'Groups',
  '/home/water/user': 'Users',
  '/home/water/balance': 'Balance',
  '/home/water/observation': 'Observations',
  '/home/water/map': 'Map',
  '/home/water/control': 'Control',

  '/home/generic': 'Generic',
  '/home/generic/devices': 'Devices',
  '/home/generic/alarm': 'Alarm',
  '/home/generic/alarm/notification': 'Notifications',
  '/home/generic/history': 'History',

  '/home/capacity': 'Capacity',
  '/home/capacity/device': 'Device',
  '/home/capacity/panel': 'Panel',
  '/home/capacity/parking': 'Parking',
  '/home/capacity/dashboard': 'Dashboard',
  '/home/capacity/map': 'Map',

  '/home/irrigation': 'Irrigation',
  '/home/irrigation/device': 'Device',

  '/home/boiler': 'Boiler',
  '/home/boiler/device': 'Device'
}

export function BreadcrumbsProvider({ children }) {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    <Breadcrumb.Item key="home">
      <Link to={'/home'}>Home</Link>
    </Breadcrumb.Item>
  ])

  function getBreadcrumbs(location) {
    const pathSnippets = location.pathname.split('/').filter((i) => i)
    const extras = pathSnippets.map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
      return (
        <Breadcrumb.Item key={url}>
          <Link to={url}>{breadcrumbNameMap[url]}</Link>
        </Breadcrumb.Item>
      )
    })
    setBreadcrumbItems(extras)
  }

  return (
    <BreadcrumbsContext.Provider value={breadcrumbItems}>
      <BreadcrumbsUpdateContext.Provider value={getBreadcrumbs}>{children}</BreadcrumbsUpdateContext.Provider>
    </BreadcrumbsContext.Provider>
  )
}
