import { useEffect, useState } from 'react'
import userService from '../../services/user.service'
import PaginationTable from '../../components/PaginationTable'
import { Flex, Spin } from 'antd'
import { extractAndConvertValues } from '../../utils/helpers'
import { countryService } from '../../services/country.service'
import { cityService } from '../../services/city.service'
import { stateService } from '../../services/state.service'

export default function Users() {
  const columns = [
    { name: 'Name', key: 'name', filter: 'text' },
    { name: 'Surname', key: 'surname', filter: 'text' },
    { name: 'Email', key: 'email', filter: 'text' },
    { name: 'Country', key: 'country', filter: 'text' },
    { name: 'City', key: 'city', filter: 'text' },
    { name: 'Role', key: 'role', filter: 'text' }
  ]

  const [tableProps, setTableProps] = useState({
    title: 'Users',
    entity: 'user',
    columns: columns,
    actions: ['edit', 'remove', 'info'],
    hasRowSelection: false,
    defaultFilter: {},
    data: []
  })

  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageSize: 10,
    total: 0
  })

  const [locationDataFetched, setLocationDataFetched] = useState(false)

  const [locationData, setLocationData] = useState({})

  const [loading, setLoading] = useState(false)

  const handleSearch = async (filters) => {
    getUsers(1, 10, filters)
  }

  const getUsers = async (page = 1, pageSize = 10, filters = tableProps.defaultFilter) => {
    const offset = (page - 1) * pageSize
    const limit = pageSize
    const filter = extractAndConvertValues(filters)

    try {
      setLoading(true)

      // Fetch user data
      const userResponse = await userService.getAllUsers(limit, offset, filter)
      const newUserList = userResponse.result

      // Update location names
      const updateLocationNames = (userData, countriesData, citiesData, stateData) => {
        return userData.map((item) => {
          let updatedItem = { ...item }

          if (item.country) {
            const matchingCountry = countriesData?.find((country) => String(country.id) === String(item.country))
            if (matchingCountry) {
              updatedItem.country = matchingCountry.name
              updatedItem.countryId = matchingCountry.id
            }
          }

          if (item.city) {
            const matchingCity = citiesData?.find((city) => String(city.id) === String(item.city))
            if (matchingCity) {
              updatedItem.city = matchingCity.name
              updatedItem.cityId = matchingCity.id
            }
          }
          if (item.state) {
            const matchingState = stateData?.find((state) => String(state.id) === String(item.state))
            if (matchingState) {
              updatedItem.state = matchingState.name
              updatedItem.stateId = matchingState.id
            }
          }

          return updatedItem
        })
      }

      const updatedUserList = updateLocationNames(
        newUserList,
        locationData?.countriesData,
        locationData?.citiesData,
        locationData?.stateData
      )

      setPagination((prevState) => ({
        ...prevState,
        currentPage: page,
        pageSize: pageSize,
        total: userResponse.totalCount
      }))

      setTableProps((prevState) => ({
        ...prevState,
        defaultFilter: filters,
        data: updatedUserList
      }))
    } catch (error) {
      console.error('Error: ', error)
    } finally {
      setLoading(false)
    }
  }

  const deleteUser = async (user) => {
    await userService.deleteUserById(user.id)
    getUsers(pagination.currentPage, pagination.pageSize)
  }

  const renderMobileTable = (columns) => {
    return columns.filter((column) => column.key === 'name' || column.key === 'surname')
  }

  const renderTabletTable = (columns) => {
    return columns.filter((column) => column.key === 'name' || column.key === 'surname' || column.key === 'email')
  }

  const fetchLocationsApi = async () => {
    try {
      const [countriesResponse, citiesResponse, stateResponse] = await Promise.all([
        countryService.getCountries(),
        cityService.getCities(),
        stateService.getStates()
      ])
      setLocationData((prev) => ({
        ...prev,
        countriesData: countriesResponse?.result,
        citiesData: citiesResponse?.result,
        stateData: stateResponse?.result
      }))
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {
    const fetchLocApi = async () => {
      setLoading(true)
      try {
        await fetchLocationsApi()
        setLocationDataFetched(true)
      } catch (err) {
        console.log(err)
      }
    }
    fetchLocApi()
  }, [])

  useEffect(() => {
    if (locationDataFetched) {
      const fetchData = async () => {
        setLoading(true)
        try {
          await getUsers(pagination.currentPage, pagination.pageSize)
        } finally {
          setLoading(false)
        }
      }
      fetchData()
      window.addEventListener('resize', resize)
      resize()
      return () => {
        window.removeEventListener('resize', resize)
      }
    }
  }, [locationDataFetched])

  const resize = () => {
    if (window.innerWidth <= 650) {
      const cols = renderMobileTable(tableProps.columns)
      setTableProps((prevState) => ({
        ...prevState,
        columns: cols
      }))
    } else if (window.innerWidth > 650 && window.innerWidth < 820) {
      const cols = renderTabletTable(tableProps.columns)
      setTableProps((prevState) => ({
        ...prevState,
        columns: cols
      }))
    } else {
      setTableProps((prevState) => ({
        ...prevState,
        columns: columns
      }))
    }
  }

  return (
    <div className="content">
      {loading ? (
        <Flex align="center" gap="middle" justify="center">
          <Spin size="large" />
        </Flex>
      ) : (
        <PaginationTable
          props={{
            ...tableProps,
            onAdd: () => getUsers(pagination.currentPage, pagination.pageSize),
            onUpdate: () => getUsers(pagination.currentPage, pagination.pageSize),
            onDelete: deleteUser,
            pagination: pagination,
            onPaginationChange: async (page, pageSize) => {
              await getUsers(page, pageSize)
            },
            onSearch: handleSearch
          }}
        />
      )}
    </div>
  )
}
