import { Route } from 'react-router-dom'
import { PrivateRoute } from '../../PrivateRoute.component'
import Users from '../../../pages/Users/users.component'
import Ocity from '../../../pages/Cities/Ocity/ocities.component'
import City from '../../../pages/Cities/Cities/cities.component'
import State from '../../../pages/Cities/States/states.component'
import Countries from '../../../pages/Cities/Countries/countries.component'
import Ambassadors from '../../../pages/Ambassadors/ambassador.component'
import Heritage from '../../../pages/Heritage/Heritage/heritage.component'
import HeritageProposal from '../../../pages/Heritage/HeritageProposal/heritageProposal.component'
import HeritageContent from '../../../pages/Heritage/HeritageContent/heritageContent.component'
import RoadRoute from '../../../pages/Road/RoadRoute/roadRoute.component'

export const DashboardRoutes = () => {
  return (
    <>
      <Route path="/dashboard">
        <Route
          path="user"
          element={
            <PrivateRoute>
              <Users />
            </PrivateRoute>
          }
        />
        <Route
          path="city/ocity"
          element={
            <PrivateRoute>
              <Ocity />
            </PrivateRoute>
          }
        />
        <Route
          path="city/city"
          element={
            <PrivateRoute>
              <City />
            </PrivateRoute>
          }
        />
        <Route
          path="city/state"
          element={
            <PrivateRoute>
              <State />
            </PrivateRoute>
          }
        />
        <Route
          path="city/country"
          element={
            <PrivateRoute>
              <Countries />
            </PrivateRoute>
          }
        />
        <Route
          path="ambassador"
          element={
            <PrivateRoute>
              <Ambassadors />
            </PrivateRoute>
          }
        />
        <Route
          path="heritage"
          element={
            <PrivateRoute>
              <Heritage />
            </PrivateRoute>
          }
        />
        <Route path="heritage/proposal" element={<HeritageProposal />} />
        <Route
          path="heritage/content"
          element={
            <PrivateRoute>
              <HeritageContent />
            </PrivateRoute>
          }
        />
        <Route
          path="road/route"
          element={
            <PrivateRoute>
              <RoadRoute />
            </PrivateRoute>
          }
        />
      </Route>
    </>
  )
}
