import React from 'react'
import { useFormik } from 'formik'
import authApi from '../../services/auth.service'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { message } from 'antd'
import { userMessages } from '../../utils/userMessages'

export default function SignUp() {
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: {
      name: '',
      surname: '',
      username: '',
      email: '',
      phone: '',
      pswd: '',
      repeat_password: '',
      gender: ''
    },
    validationSchema: Yup.object({
      name: Yup.string('Invalid field').required('Required'),
      surname: Yup.string('Invalid surname').required('Required'),
      username: Yup.string('Invalid username').required('Required'),
      email: Yup.string().email('Invalid email').required('Required'),
      phone: Yup.string('Invalid phone').required('Required'),
      pswd: Yup.string().min(8, 'Must be at least 8 characters').required('Required'),
      repeat_password: Yup.string()
        .min(8, 'Must be at least 8 characters')
        .oneOf([Yup.ref('pswd'), null], 'Passwords must match')
        .required('Required'),
      gender: Yup.string('Invalid field').required('Required')
    }),
    onSubmit: async (values) => {
      try {
        await authApi.register(values)
        message.success(userMessages.registerOK)
        navigate('/sign-in')
      } catch (error) {
        message.error(error.message ?? userMessages.registerFail)
      }
    }
  })

  return (
    <div className="auth-wrapper">
      <div
        className="auth-inner"
        style={{
          width: '500px',
          padding: '40px',
          zIndex: '10',
          position: 'relative'
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <h3>Crear Usuario</h3>

          <div className="mb-3 d-flex gap-2">
            <div>
              <label>Nombre</label>
              <input
                id="name"
                name="name"
                type="text"
                className={`form-control ${formik.errors.name ? 'is-invalid' : ''}`}
                placeholder="Introduzca Nombre"
                onChange={formik.handleChange}
              />
              {formik.errors.name && <p className="m-0 invalid-feedback d-block">{formik.errors.name}</p>}
            </div>
            <div>
              <label>Apellidos</label>
              <input
                id="name"
                name="surname"
                type="text"
                className={`form-control ${formik.errors.name ? 'is-invalid' : ''}`}
                placeholder="Introduzca Nombre"
                onChange={formik.handleChange}
                // value={formik.values.username}
              />
              {formik.errors.surname && <p className="m-0 invalid-feedback d-block">{formik.errors.surname}</p>}
            </div>
          </div>

          <div className="mb-3">
            <label>Alias</label>
            <input
              id="username"
              name="username"
              type="text"
              className={`form-control ${formik.errors.name ? 'is-invalid' : ''}`}
              placeholder="Introduzca alias"
              onChange={formik.handleChange}
              // value={formik.values.username}
            />
            {formik.errors.username && <p className="m-0 invalid-feedback d-block">{formik.errors.username}</p>}
          </div>
          <div className="mb-3">
            <label>Email </label>
            <input
              id="email"
              name="email"
              type="email"
              className={`form-control ${formik.errors.name ? 'is-invalid' : ''}`}
              placeholder="Enter email"
              onChange={formik.handleChange}
              // value={formik.values.username}
            />
            {formik.errors.email && <p className="m-0 invalid-feedback d-block">{formik.errors.email}</p>}
          </div>

          <div className="mb-3">
            <label>Número de Teléfono </label>
            <input
              id="email"
              name="phone"
              type="phone"
              className={`form-control ${formik.errors.name ? 'is-invalid' : ''}`}
              placeholder="Enter email"
              onChange={formik.handleChange}
              // value={formik.values.username}
            />
            {formik.errors.phone && <p className="m-0 invalid-feedback d-block">{formik.errors.phone}</p>}
          </div>
          <div className="mb-3 d-flex gap-2">
            <div>
              <label>Contraseña</label>
              <input
                id="pswd"
                name="pswd"
                type="password"
                className={`form-control ${formik.errors.name ? 'is-invalid' : ''}`}
                placeholder="Introduzca contraseña"
                onChange={formik.handleChange}
                // value={formik.values.pswd}
              />
              {formik.errors.pswd && <p className="m-0 invalid-feedback d-block">{formik.errors.pswd}</p>}
            </div>
            <div>
              <label>Confirmar contraseña</label>
              <input
                id="repeat_password"
                name="repeat_password"
                type="password"
                className={`form-control ${formik.errors.name ? 'is-invalid' : ''}`}
                placeholder="Introduzca contraseña"
                onChange={formik.handleChange}
                // value={formik.values.pswd}
              />
              {formik.errors.repeat_password && (
                <p className="m-0 invalid-feedback d-block">{formik.errors.repeat_password}</p>
              )}
            </div>
          </div>

          <div className="mb-3">
            <label>Género </label>
            <div className="d-flex align-items-center gap-3">
              <div>
                <input id="radioM" type="radio" radioGroup="gender" name="gender" onChange={formik.handleChange} />{' '}
                <label htmlFor="radioM">Masculino</label>
              </div>
              <div>
                <input id="radioF" type="radio" radioGroup="gender" name="gender" onChange={formik.handleChange} />{' '}
                <label htmlFor="radioF">Femenino</label>
              </div>
              <div>
                <input id="radioO" type="radio" radioGroup="gender" name="gender" onChange={formik.handleChange} />{' '}
                <label htmlFor="radioO">Otro</label>
              </div>
            </div>
          </div>

          <div className="d-grid">
            <button type="submit" className="btn btn-primary">
              Enviar
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
