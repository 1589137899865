import { Button, Col, Form, Input, Row, Divider, Upload, Modal, message } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Formik, ErrorMessage } from 'formik'
import { Heritage } from '../../../../models/Heritage'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import heritageService from '../../../../services/heritage.service'
import LocationSelector from '../../../../components/LocationSelector/locationSelector.component'
import { draftService } from '../../../../services/draft.service'
import DropdownCountries from '../../../../components/DropdownCountries/DropdownCountries.component'
import DropdownStates from '../../../../components/DropdownStates/DropdownStates.component'
import DropdownCities from '../../../../components/DropdownCities/DropdownCities.component'
import { getBase64 } from '../../../../utils/helpers'
import { PlusOutlined } from '@ant-design/icons'
import CaptchaComponent from '../../../../components/Captcha'
import { userMessages } from '../../../../utils/userMessages'

const HeritageForm = (props) => {
  const { element, onAdd, onUpdate } = props
  const { t } = useTranslation()
  // const { token } = useToken()
  const captchaRef = useRef(null)
  const [draft, setDraft] = useState()
  const [heritage, setHeritage] = useState(element ? element : new Heritage())
  const [heritageField] = useState('')
  const [isVerified, setIsVerified] = useState(false)

  const [bibliographyLinksList, setBibliographyLinksList] = useState([])
  const [interestLinksList, setInterestLinksList] = useState([])
  const [heritageTags] = useState([])

  if (heritageField && ![1, 2, 3].includes(heritageField)) return heritageTags

  const onFinishFailed = (errorInfo) => {
    message.error(`${userMessages.error} ${errorInfo}`)
  }

  const validate = (values) => {
    const errors = {}
    if (!values?.date) errors.date = 'The date is required'
    if (!values?.organization) errors.organization = 'The organization is required'
    if (!values?.email) errors.email = 'The email is required'
    if (!values?.country_id) errors.country_id = 'The country is required'
    if (!values?.state_id) errors.state_id = 'The state is required'
    if (!values?.city_id) errors.city_id = 'The city is required'
    if (!values?.name) errors.name = 'The name is required'
    if (!values?.short_heritage_description)
      errors.short_heritage_description = 'The heritage short description is required'
    if (!values?.extended_heritage_description)
      errors.extended_heritage_description = 'The heritage extended description is required'
    if (!values?.short_heritage_description_local)
      errors.short_heritage_description_local = 'The local heritage short description is required'
    if (!values?.extended_heritage_description_local)
      errors.extended_heritage_description_local = 'The local heritage extended description is required'
    if (!values?.heritage_field_id) errors.heritage_field_id = 'The heritage field is required'
    if (!values?.periodicity) errors.periodicity = 'The periodicity is required'
    if (!values?.ownership) errors.ownership = 'The ownership is required'
    if (!values?.latitude) errors.latitude = 'The latitude is required'
    if (!values?.longitude) errors.longitude = 'The longitude is required'
    if ([1, 2, 3].includes(values?.heritage_field_id) && !values?.subtype) errors.subtype = 'The subtype is required'

    for (const error in errors) {
      message.error(errors[error])
      break
    }

    return errors
  }

  // Handle image Upload
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [fileList, setFileList] = useState([])

  const handleCancel = () => setPreviewOpen(false)
  const handlePreview = async (file, open = true) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewOpen(open)
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
  }
  const handleChangeUpload = ({ fileList: newFileList }) => {
    setPreviewImage('')
    setFileList(newFileList)
  }
  const handleBeforeUploadImage = (file) => {
    setFileList([...fileList, file])
    return false
  }

  const saveDraft = async (values) => {
    draftService.saveDraft('heritage', { ...values, bibliography: bibliographyLinksList, links: interestLinksList })
    message.success(userMessages.draftSaved)
  }

  const removeDraft = async () => {
    await draftService.removeDraft('heritage')
    message.success(userMessages.draftRemoved)
    setDraft(null)
  }

  const restoreDraft = async () => {
    setHeritage(draft.value)
    message.success(userMessages.draftRestored)
    // Now remove the draft by its ID after it's restored
    if (draft?.id) {
      await removeDraft(draft.id) // Remove the draft by its ID
    }
    setDraft(null)
  }

  useEffect(() => {
    if (!props.isModalOpen) return
    if (!element) {
      setBibliographyLinksList([])
      setInterestLinksList([])
      setFileList([])
      setPreviewImage('')
      draftService.getDraft('heritage').then((draft) => setDraft(draft))
      return
    }

    heritageService.getHeritageProposalById(element.id).then((heritage) => {
      setHeritage(heritage)
      setBibliographyLinksList(heritage.bibliography ?? [])
      setInterestLinksList(heritage.links ?? [])
    })
  }, [element, props])

  useEffect(() => {
    if (heritage.image && heritage.image !== '') {
      handlePreview({ url: heritage.image }, false)
    }
  }, [heritage])

  const handleRecaptchaChange = (value) => {
    if (value) {
      setIsVerified(true)
    } else {
      setIsVerified(false)
    }
  }

  useEffect(() => {
    if (captchaRef.current) {
      captchaRef.current.reset()
      setIsVerified(false)
    }
  }, [props])

  const imageUploadBtn = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8
        }}
      >
        Upload
      </div>
    </div>
  )

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...heritage }}
      validate={validate}
      validateOnChange={false}
      onSubmit={async (values, actions) => {
        const form = { ...values }
        form.image = fileList[0]?.originFileObj
        form.date = dayjs(values.date).format('YYYY-MM-DD')
        try {
          if (!isVerified) {
            throw new Error('Please Verfiy Capctha')
          }
          if (element) {
            await heritageService.updateHeritageProposalById(heritage.id, form)
            onUpdate?.()
            message.success(userMessages.updated)
          } else {
            await heritageService.createHeritageProposal(form)
            onAdd?.()
            message.success(userMessages.created)
          }
          actions.resetForm()
          setFileList([])
          setPreviewImage('')
          setBibliographyLinksList([])
          setInterestLinksList([])
        } catch (error) {
          message.error(`${userMessages.error} ${error.message}`)
        }
        captchaRef.current.reset()
        setIsVerified(false)
        actions.setSubmitting(false)
      }}
    >
      {({ errors, touched, values, handleChange, handleSubmit, setFieldValue }) => (
        <Form
          style={{
            maxWidth: 900
          }}
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
        >
          {draft && (
            <div className="alert alert-light d-flex align-items-center" role="alert">
              <p className="flex-grow-1 m-0">You have a draft saved. Do you want to restore it?</p>
              <div className="d-flex gap-3">
                <button className="btn btn-light btn-sm" type="button" onClick={removeDraft}>
                  Remove
                </button>
                <button className="btn btn-light btn-sm" type="button" onClick={restoreDraft}>
                  Restore
                </button>
              </div>
            </div>
          )}

          <Divider orientation="left" className={'form-divider'}>
            Heritage Information
          </Divider>

          <Row gutter={24}>
            <Col xs={24} md={12}>
              <Form.Item
                required
                label={t('Name of the Heritage')}
                name="name"
                className={{ 'input-error': errors.name && touched.name }}
              >
                <Input name="name" id="name" onChange={handleChange} value={values.name} />
                <ErrorMessage name="name" />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <DropdownCountries
                error={errors.country_id}
                value={values.country_id}
                onChange={(value) => {
                  setFieldValue('country_id', value)
                  setFieldValue('state_id', null)
                  setFieldValue('city_id', null)
                }}
              />
            </Col>
            <Col xs={24} sm={12}>
              <DropdownStates
                error={errors.state_id}
                country_id={values.country_id}
                value={values.state_id}
                onChange={(value) => {
                  setFieldValue('state_id', value)
                  setFieldValue('city_id', null)
                }}
              />
            </Col>
            <Col xs={24} md={12}>
              <DropdownCities
                error={errors.city_id}
                state_id={values.state_id}
                value={values.city_id}
                onChange={(value) => setFieldValue('city_id', value)}
                originEntity='heritage_proposal'
              />
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                required
                label={t('Email')}
                name="email"
                className={{ 'input-error': errors.email && touched.email }}
              >
                <>
                  <Input name="email" id="email" onChange={handleChange} value={values.email} />
                  <ErrorMessage name="email" />
                </>
              </Form.Item>
            </Col>

            <Col xs={24} sm={12}>
              <Form.Item
                label={t('Image Author Copyright')}
                name="image_copyright"
                className={{ 'input-error': errors.image_copyright && touched.image_copyright }}
              >
                <>
                  <Input
                    name="image_copyright"
                    id="image_copyright"
                    onChange={handleChange}
                    value={values.image_copyright}
                  />
                  <ErrorMessage name="image_copyright" />
                </>
              </Form.Item>
            </Col>

            <Col md={12} sm={24}>
              <Form.Item label={t('Image')}>
                <>
                  <Upload
                    className="w-auto"
                    accept="image/png, image/jpeg"
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChangeUpload}
                    beforeUpload={handleBeforeUploadImage}
                  >
                    {fileList.length == 1 ? null : imageUploadBtn}
                  </Upload>
                  {heritage.image !== '' && previewImage !== '' && fileList.length == 0 && (
                    <div className="image-preview">
                      <img alt="example" src={previewImage} />
                    </div>
                  )}
                  <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                    <img
                      alt="example"
                      style={{
                        width: '100%'
                      }}
                      src={previewImage}
                    />
                  </Modal>
                </>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Divider orientation="left" className={'form-divider'}>
                Location
              </Divider>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={t('Latitude')}
                name="latitude"
                className={{ 'input-error': errors.latitude && touched.latitude }}
              >
                <Input name="latitude" id="latitude" onChange={handleChange} value={values.latitude} />
                <ErrorMessage name="latitude" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={t('Longitude')}
                name="longitude"
                className={{ 'input-error': errors.longitude && touched.longitude }}
              >
                <Input name="longitude" id="longitude" onChange={handleChange} value={values.longitude} />
                <ErrorMessage name="longitude" />
              </Form.Item>
            </Col>

            <LocationSelector
              height="300px"
              onLocationSelected={({ lat, lng }) => {
                setFieldValue('latitude', lat)
                setFieldValue('longitude', lng)
              }}
              lat={values.latitude}
              lng={values.longitude}
            />
            <Col xs={24} md={12}>
              <CaptchaComponent ref={captchaRef} onChange={handleRecaptchaChange} isVerified={isVerified} />
            </Col>
            <Col span={24}>
              <div className="d-flex gap-4">
                {localStorage.getItem('user') && (
                  <Button style={{ width: '50%' }} type="primary" htmlType="button" onClick={() => saveDraft(values)}>
                    Save For Later
                  </Button>
                )}
                <Button style={{ width: '50%' }} type="primary" htmlType="submit" className="mx-auto">
                  Publish
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default HeritageForm
