import { Routes } from 'react-router-dom'
import { MapRoutes } from './Routes/MapRoutes/MapRoutes'
import { DashboardRoutes } from './Routes/DashboardRoutes/DashboardRoutes'
import { AuthRoutes } from './Routes/AuthRoutes/AuthRoutes'
import { UserRoutes } from './Routes/UserRoutes/UserRoutes'

function AllRoutes() {
  return (
    <Routes>
      {MapRoutes()}
      {DashboardRoutes()}
      {AuthRoutes()}
      {UserRoutes()}
    </Routes>
  )
}

export default AllRoutes
