export const columns = [
  {
    name: 'Date',
    key: 'date',
    filter: 'date'
  },
  {
    name: 'Manifestation name',
    key: 'name',
    filter: 'text'
  },
  {
    name: 'Country',
    key: 'country.name',
    filter: 'text'
  },
  {
    name: 'City',
    key: 'city.name',
    filter: 'text'
  },
  {
    name: 'Image Author',
    key: 'image_copyright',
    filter: 'text'
  },
  {
    name: 'Status',
    key: 'status',
    filter: 'text'
  }
]
