import React, { useEffect, useState } from 'react'
// Estilos
import styles from './MapMenu.module.css'
import MenuItem from './MenuItem/MenuItem.component'
import heritageService from '../../../services/heritage.service'
import { getCategoryIcon } from '../../../utils/helpers'
import { Button, Checkbox, Input, Modal } from 'antd'
import MAGNIFIER_INDEX_ICON from '../../../assets/mapIcons/magnifier_index_icon.png'
//import MAP_SEARCH_ICON from '../../../assets/mapIcons/map_search_icon.png';
import SearchCityModal from './SearchCityModal/SearchCityModal.component' // Importa el nuevo componente

const MapMenu = ({ onUpdateCategories, onSearch }) => {
  const [categories, setCategories] = useState([])
  const [modalSearch, setModalSearch] = useState(false)
  const [categoriesSelected, setCategoriesSelected] = useState([])
  const [unescoProtected, setUnescoProtected] = useState()
  const [country, setCountry] = useState('')
  const [city, setCity] = useState('')
  const [description, setDescription] = useState('')
  const [cityNetwork, setCityNetwork] = useState('')
  const [modalSearchCity, setModalSearchCity] = useState(false)

  const toggleCategory = (category) => {
    if (categoriesSelected.includes(category)) {
      setCategoriesSelected((value) => value.filter((item) => item !== category))
      return
    }

    setCategoriesSelected((value) => [...value, category])
  }

  const closeAndReset = () => {
    setModalSearch(false)
    setCategoriesSelected([])
    setUnescoProtected(undefined)
    setCountry('')
    setCity('')
    setDescription('')
    setCityNetwork('')
    onSearch?.({
      categories: [],
      unescoProtected: undefined,
      country: '',
      city: '',
      description: '',
      cityNetwork: ''
    })
  }

  const search = () => {
    onSearch?.({
      categories: categoriesSelected,
      unescoProtected,
      country,
      city,
      description,
      cityNetwork
    })
    setModalSearch(false)
  }

  useEffect(() => {
    heritageService.getHeritageFieldsTypes().then((fields) => {
      setCategories(fields)
    })
  }, [])

  useEffect(() => {
    onUpdateCategories?.(categoriesSelected)
  }, [categoriesSelected])

  return (
    <div id="MapMenu">
      <div className={styles.mainContainermapmenu}>
        <header>
          <h5>Filter</h5>
        </header>

        {categories.map((item) => (
          <MenuItem
            key={item.id}
            title={item.name}
            icon={getCategoryIcon(item.id)}
            setActivated={() => {
              toggleCategory(item.id)
            }}
            linkActivated={categoriesSelected.includes(item.id)}
            location={location.pathname}
          />
        ))}

        <MenuItem
          style={{ bot: '0 !important' }}
          title={'Advanced Search'}
          icon={MAGNIFIER_INDEX_ICON}
          setActivated={() => {
            setModalSearch(true)
          }}
        />
        {/*<MenuItem
          style={{ bot: '0 !important' }}
          title={'City/Hheritage Search'}
          icon={MAP_SEARCH_ICON}
          setActivated={() => {
            setModalSearchCity(true);
          }}
        />*/}
      </div>

      <Modal
        closable={false}
        maskClosable={false}
        width={800}
        footer={null}
        title={'Advanced Search'}
        open={modalSearch}
        onCancel={() => setModalSearch(false)}
      >
        <div className="row">
          <div className="col-6 mb-3">
            <div className="form-group">
              <label htmlFor="country">Country</label>
              <Input name="country" id="country" onChange={(e) => setCountry(e.target.value)} value={country} />
            </div>
          </div>
          <div className="col-6 mb-3">
            <div className="form-group">
              <label htmlFor="city">City</label>
              <Input name="city" id="city" onChange={(e) => setCity(e.target.value)} value={city} />
            </div>
          </div>
          <div className="col-6 mb-3">
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <Input
                name="description"
                id="description"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
            </div>
          </div>
          <div className="col-6 mb-3">
            <div className="form-group">
              <label htmlFor="city_network">City Network</label>
              <Input
                name="city_network"
                id="city_network"
                onChange={(e) => setCityNetwork(e.target.value)}
                value={cityNetwork}
              />
            </div>
          </div>
        </div>

        <label className="mb-2">Category</label>
        <div className="d-flex flex-wrap gap-2">
          {categories.map((item) => (
            <Checkbox
              key={item.id}
              className="text-nowrap"
              onChange={() => toggleCategory(item.id)}
              checked={categoriesSelected.includes(item.id)}
            >
              {item.name}
            </Checkbox>
          ))}
        </div>

        <Checkbox
          className="text-nowrap mt-5"
          onChange={(e) => setUnescoProtected(e.target.checked)}
          checked={unescoProtected}
        >
          UNESCO protected
        </Checkbox>

        <div className="mt-5 text-center d-flex gap-3 justify-content-center">
          <Button type="default" size="large" onClick={closeAndReset}>
            Close
          </Button>
          <Button type="primary" size="large" onClick={search}>
            Search
          </Button>
        </div>
      </Modal>

      <SearchCityModal modalSearchCity={modalSearchCity} setModalSearchCity={setModalSearchCity} />
    </div>
  )
}

export default MapMenu
