import { AimOutlined } from '@ant-design/icons'
import { theme } from 'antd'
import { useNavigate } from 'react-router-dom'

const { useToken } = theme

function SeeInTheMapButton({ element, entity }) {
  const navigate = useNavigate()
  const { token } = useToken()

  function openInMap() {
    if (entity == 'ocity') navigate(`/city/${element.city_id}`)
    if (entity == 'heritage') navigate(`/city/${element.city_id}/heritage/${element.id}`)
  }

  return <AimOutlined style={{ fontSize: 'large', color: token.colorPrimary }} onClick={openInMap} />
}

export default SeeInTheMapButton
