import { Form, Select } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { stateService } from '../../services/state.service'

const DropdownStates = ({ country_id, value = null, onChange, error, disabled = null }) => {
  const { t } = useTranslation()
  const [statesList, setStatesList] = useState([])

  const getStates = async (id) => {
    const list = await stateService.getStatesByCountryId(id)

    setStatesList(list.map((item) => ({ label: item.name, value: item.id })))
  }

  useEffect(() => {
    if (country_id) {
      getStates(country_id)
    }
  }, [country_id])

  return (
    <Form.Item required label={t('State')} className={{ 'input-error': error }}>
      <>
        <Select
          id="state_id"
          name="state_id"
          options={statesList}
          optionFilterProp="children"
          placeholder="Choose a State"
          showSearch
          style={{
            width: '100%'
          }}
          disabled={disabled}
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          value={value}
          onChange={onChange}
        />
        {error && (
          <p className="invalid-feedback d-block" style={{ fontSize: '14px' }}>
            {error}
          </p>
        )}
      </>
    </Form.Item>
  )
}

export default DropdownStates
