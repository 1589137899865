import http from '../http-common'
import { ENV } from '../utils'

class RegionService {
  async getRegions() {
    const { data } = await http.get(`/api/${ENV.API_ROUTES.REGION}`)
    return data
  }

  async getSubregions() {
    const { data } = await http.get(`/api/${ENV.API_ROUTES.SUBREGION}`)
    return data
  }

  async getRegionById(id) {
    const { data } = await http.get(`/api/${ENV.API_ROUTES.REGION}/${id}`)
    return data
  }

  async getSubregionsByRegionId(id) {
    const { data } = await http.get(`/api/${ENV.API_ROUTES.SUBREGION}/${id}`)
    return data
  }

  async getSubregionById(id) {
    const { data } = await http.get(`/api/${ENV.API_ROUTES.SUBREGION}/${id}`)
    return data
  }
}

export const regionService = new RegionService()
