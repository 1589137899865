export const userMessages = {
  deleted: 'Deleted successfully!',
  updated: 'Updated successfully!',
  created: 'Created successfully!',
  error: 'An error occurred. Reason:',
  draftSaved: 'Draft saved!',
  draftRemoved: 'Draft removed!',
  draftRestored: 'Draft restored!',
  fileUploaded: 'File uploaded!',
  registerOK: 'New user registered!',
  registerFail: 'A problem was detected',
  loginOK: 'Welcome!',
  loginFail: 'Incorrect credentials!',
  detailsNotFound: 'No Content Was Found',
  recoverEmail: 'A password recovery email has been sent to your email.'
}
