import { Form, Select } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import userService from '../../services/user.service'

const DropdownUsers = ({ value = null, onChange, error }) => {
  const { t } = useTranslation()
  const [usersList, setUsersList] = useState([])

  const getUsers = async () => {
    const list = await userService.allUserList()
    setUsersList(list.result.map((item) => ({ label: item.name + ' ' + item.surname, value: item.id })))
  }

  useEffect(() => {
    getUsers()
  }, [])

  return (
    <Form.Item required label={t('OwnerShip')} className={{ 'input-error': error }}>
      <>
        <Select
          id="ownership"
          name="ownership"
          options={usersList}
          optionFilterProp="children"
          placeholder="Choose a OwnerShip"
          showSearch
          style={{
            width: '100%'
          }}
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          value={value}
          onChange={onChange}
        />
        {error && (
          <p className="invalid-feedback d-block" style={{ fontSize: '14px' }}>
            {error}
          </p>
        )}
      </>
    </Form.Item>
  )
}

export default DropdownUsers
