import { message } from 'antd'
import { jsPDF } from 'jspdf'
import noAvailableImage from '../../../../assets/NO_AVAILABLE_IMG.png'
import ocityLogo from '../../../../assets/logo-ocity-beta-400.png'

export const PDFGenerator = ({ cityInfo, descriptionLocalLanguage }) => {
  const MARGINS = {
    left: 10,
    top: 10
  }

  const FONT_SIZES = {
    title: 16,
    text: 12,
    copyright: 10
  }

  const LINE_SPACING = 0 // Espaciado entre líneas

  const generatePDF = async () => {
    try {
      const heritageEntityPdf = new jsPDF()
      const pageHeight = heritageEntityPdf.internal.pageSize.height
      const pageWidth = heritageEntityPdf.internal.pageSize.width
      const textWidth = pageWidth - MARGINS.left * 2
      // Función para convertir imágenes a Base64
      const convertImageToBase64 = (url, fallbackImage) => {
        return new Promise((resolve) => {
          const img = new Image()
          img.crossOrigin = 'Anonymous'

          img.onload = () => {
            const canvas = document.createElement('canvas')
            canvas.width = img.width
            canvas.height = img.height
            const ctx = canvas.getContext('2d')
            ctx.drawImage(img, 0, 0)
            resolve(canvas.toDataURL())
          }

          img.onerror = () => {
            const fallbackImg = new Image()
            fallbackImg.onload = () => {
              const canvas = document.createElement('canvas')
              canvas.width = fallbackImg.width
              canvas.height = fallbackImg.height
              const ctx = canvas.getContext('2d')
              ctx.drawImage(fallbackImg, 0, 0)
              resolve(canvas.toDataURL())
            }
            fallbackImg.src = fallbackImage
          }

          img.src = url
        })
      }

      // Función para agregar texto con paginación dinámica
      const addTextWithPagination = (text, x, y, fontSize) => {
        heritageEntityPdf.setFontSize(fontSize)
        const lines = heritageEntityPdf.splitTextToSize(text, textWidth)
        let cursorY = y

        lines.forEach((line) => {
          if (cursorY + fontSize > pageHeight - MARGINS.top) {
            heritageEntityPdf.addPage()
            cursorY = MARGINS.top
          }
          heritageEntityPdf.text(line, x, cursorY)
          cursorY += fontSize / 2
        })
      }

      // Función para agregar el logotipo en la esquina superior izquierda de cada página
      const addLogoToPage = async () => {
        try {
          const base64Logo = await convertImageToBase64(ocityLogo)
          const logoWidth = 30 // Ancho del logotipo
          const logoHeight = 10 // Alto del logotipo
          heritageEntityPdf.addImage(base64Logo, 'PNG', MARGINS.left, 5, logoWidth, logoHeight)
        } catch (error) {
          console.error('Error loading logo:', error)
        }
      }
      // Función principal para generar el contenido del PDF
      const createPDFContent = async () => {
        await addLogoToPage()
        const base64Image = await convertImageToBase64(cityInfo?.image, noAvailableImage)

        // Agregar título
        heritageEntityPdf.setFont('Helvetica', 'normal')
        heritageEntityPdf.setFontSize(FONT_SIZES.title)

        // Convertir el texto a mayúsculas
        const titleText = (cityInfo?.name || 'Untitled').toUpperCase()
        const subTitleText = `${cityInfo?.city.name}, ${cityInfo?.country?.name} (${cityInfo?.country?.region}) `
        // Calcular la posición centrada
        const textWidth = heritageEntityPdf.getTextWidth(titleText)
        const centeredX = (pageWidth - textWidth) / 2
        heritageEntityPdf.text(titleText, centeredX, MARGINS.top)
        heritageEntityPdf.text(subTitleText, centeredX, MARGINS.top + 10)

        // Agregar imagen
        const imageHeight = 150
        const imageWidth = 190
        const imageY = MARGINS.top + 20

        heritageEntityPdf.addImage(base64Image, 'JPEG', MARGINS.left, imageY, imageWidth, imageHeight)

        // Agregar copyright
        let cursorY = imageY + imageHeight + 10
        if (cityInfo?.image_copyright) {
          heritageEntityPdf.setFontSize(FONT_SIZES.copyright)
          heritageEntityPdf.text(`Copyright: ${cityInfo.image_copyright}`, MARGINS.left, cursorY)
          cursorY += FONT_SIZES.copyright + LINE_SPACING
        }

        // Agregar descripción
        const description = descriptionLocalLanguage
          ? cityInfo?.extended_local_heritage_description
          : cityInfo?.extended_heritage_description

        if (description) {
          addTextWithPagination(description, MARGINS.left, cursorY, FONT_SIZES.text)
        }

        // Guardar el PDF
        heritageEntityPdf.save(`${cityInfo?.name || 'document'}.pdf`)
      }

      await createPDFContent()
    } catch (error) {
      message.error('Error generating PDF')
      console.error('Error generating PDF:', error)
    }
  }

  return (
    <i
      className="bi bi-cloud-arrow-down"
      style={{ cursor: 'pointer' }}
      onClick={generatePDF}
      title="Download as PDF"
    ></i>
  )
}
