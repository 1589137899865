/* eslint-disable no-undef */
// const API_VERSION = "v1_1"
export const ENV = {
  BASE_API: `${process.env.REACT_APP_SERVER_IP}`,
  PRODUCTION_API: `${process.env.REACT_APP_PRODU_SERVER_API}`,
  EXTERNAL_OCITY_WEB_PAGES: `${process.env.REACT_APP_EXTERNAL_OCITY_PAGES}`,
  PRODUCTION_S3_OBJECT_STORAGE: `${process.env.REACT_APP_PRODU_S3_OBJECT_STORAGE}`,
  AUTH_API: `${process.env.REACT_APP_AUTH_SERVER_IP}`,
  PUBLIC_URL: `${process.env.PUBLIC_URL}`,
  API_ROUTES: {
    REGISTER: 'register',
    LOGIN: 'login',
    MEASURE: 'measure',
    USER: 'users',
    AMBASSADOR: 'ambassadors',
    CITY_OCITY: 'city_ocity',
    HERITAGE: 'heritage',
    DRAFT: 'drafts',
    ROAD_ROUTES: 'road_route',
    COUNTRY: 'country',
    REGION: 'region',
    SUBREGION: 'subregion',
    STATE: 'state',
    CITY: 'city',
    Geographical_scopes: 'geographicalscope',
    FORGOT_PASSWORD: 'forgot-password',
    SET_PASSWORD: 'update-password'
  },
  JWT: {
    ACCESS: 'token',
    REFRESH: 'refreshToken'
  }
}

export const ROLES = {
  SUPER_ADMIN: 1,
  FOCAL_POINT: 2,
  ACTIVIST: 3,
  SCHOOLING_ALLY: 4,
  CITIZENS: 5,
  NO_ROL: 6
}

export const ROLES_NAMES = {
  1: 'Super Admin',
  2: 'Focal Point',
  3: 'Activist',
  4: 'Schooling Ally',
  5: 'Citizens',
  6: 'No Rol'
}

export const educationOptions = [
  { label: 'University Master', value: 'University Master' },
  { label: 'University', value: 'University' },
  { label: 'Vocational Educational Trainer (High)', value: 'Vocational Educational Trainer (High)' },
  { label: 'Vocational Educational Trainer', value: 'Vocational Educational Trainer' },
  { label: 'Bachelor', value: 'Bachelor' },
  { label: 'Secondary', value: 'Secondary' },
  { label: 'Primary', value: 'Primary' }
]

export const gradeOptions = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' }
]
