export class CityOcity {
  constructor() {
    this.id = ''
    this.country_id = null
    this.state_id = null
    this.city_id = null
    this.hall_name = ''
    this.hall_email = ''
    this.ambassador = ''
    this.location = ''
    this.latitude = ''
    this.longitude = ''
    this.validators_names = ''
    this.validators_users = []
    this.city_networks = [new CityNetwork()]
    this.enrollment_letter = ''
    this.document = ''
    this.image = ''
    this.hidden_city_content = ''
    this.city_description_en = ''
    this.city_description_local = ''
  }
}

export class CityNetwork {
  constructor() {
    this.name = ''
    this.name_english = ''
    this.url = ''
  }
}
