import { Drawer, List } from 'antd'
import { MessageFilled } from '@ant-design/icons'
import styles from './NotificationsDrawer.module.css'

const NotificationsDrawer = ({ onClose, open, notifications }) => {
  let notificationItems = []

  for (let i = 0; i < notifications.length; i++) {
    notificationItems.push(
      <List.Item key={i} className={styles.item} style={{ padding: '1rem' }}>
        <List.Item.Meta
          avatar={<MessageFilled />}
          key={i}
          title={notifications[i].title}
          description={notifications[i].description}
        />
      </List.Item>
    )
  }

  return (
    <Drawer title="Notificaciones:" placement="right" onClose={onClose} open={open}>
      <List>{notificationItems}</List>
    </Drawer>
  )
}

export default NotificationsDrawer
