import http from '../http-common'
import { ENV } from '../utils'

class StateService {
  async getStates(limit, offset, filters) {
    const { data } = await http.post(`/api/${ENV.API_ROUTES.STATE}?limit=${limit ?? 0}&offset=${offset ?? 0}`, filters)
    return data
  }

  async getStateById(id) {
    const { data } = await http.get(`/api/${ENV.API_ROUTES.STATE}/${id}`)
    return data
  }

  async getStatesByCountryId(country_id) {
    const { data } = await http.get(`/api/${ENV.API_ROUTES.STATE}/country/${country_id}`)
    return data
  }

  async editStateById(id, data) {
    const { state } = await http.put(`/api/${ENV.API_ROUTES.STATE}/${id}`, data)
    return state
  }

  async createState(data) {
    const { state } = await http.post(`/api/${ENV.API_ROUTES.STATE}`, data)
    return state
  }

  async deleteStateById(cityId) {
    const { data } = await http.delete(`/api/${ENV.API_ROUTES.STATE}/${cityId}`)
    return data
  }
}

export const stateService = new StateService()
