import React, { useEffect } from 'react'
import styles from './SubMenu.module.css'
import { Link } from 'react-router-dom'
import LeftMenuHooks from '../LeftMenuHooks.hooks'

import $ from 'jquery'

const SubMenu = ({ title, icon, subMenus, setActivated, linkActivated, location }) => {
  const { hide } = LeftMenuHooks()

  const activateComponent = () => {
    $('#' + title).slideToggle('fast')
    setActivated()
  }

  useEffect(() => {
    if (!linkActivated) $('#' + title).slideUp('fast')
  }, [linkActivated])

  const hideLeftMenu = () => {
    if (window.innerWidth <= 750) {
      hide()
    }
  }

  return (
    <div className={[linkActivated ? styles.topContainer : '']}>
      <div
        id={title + 'parent'}
        className={[styles.mainContainer, linkActivated ? styles.menuActivated : ''].join(' ')}
        onClick={() => {
          activateComponent()
        }}
      >
        <p>{title}</p>
        <i className={icon}></i>
      </div>
      <div id={title} className={styles.containerSubMenus}>
        {subMenus?.map(
          (subMenu) =>
            subMenu.title && (
              <Link
                key={subMenu.path}
                to={subMenu.path}
                onClick={() => {
                  hideLeftMenu()
                }}
              >
                <div className={styles.containerSubMenu}>
                  <p style={{ color: location.toLowerCase() === subMenu.path.toLowerCase() ? '#20c997' : '' }}>
                    {subMenu.title}
                  </p>
                </div>
              </Link>
            )
        )}
      </div>
    </div>
  )
}

export default SubMenu
