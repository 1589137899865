import React from 'react'
import NavBar from '../../components/NavBar/NavBar.component'
import LeftMenu from '../../components/LeftMenu/LeftMenu.component'
import { CookiePolicy } from '../../components/CookiesPolicy'
// import { Footer } from 'antd/es/layout/layout'

export default function MainLayout({ children }) {
  return (
    <>
      <NavBar />
      <LeftMenu />
      {children}
      <CookiePolicy />
      {/* <Footer /> */}
    </>
  )
}
