const road_routes = [
  {
    id: 33,
    name: 'Torres y Castillos mirando al mar (Valencia)',
    type: '1',
    heritages_ids: [1, 4, 6],
    country: 'ES',
    description_local:
      'En esta ruta se recorren varios pueblos de la Comarca de la Ribera Baja y de la Safor, en los cuales hay o había construcciones defensivas (torres, murallas o castillos). Estas poblaciones al estar situadas en la costa cuenta con un gran número de construcciones defensivas, que servían para proteger al pueblo de los posibles ataques piratas que se producían desde el mar.',
    tags_group: 90,
    links_interest: [
      { name: 'Safor Turisme', url: 'https://www.saforturisme.org/es/patrimonio-cultural/les-torres-de-guaita/' }
    ],
    date: '2022-09-20',
    id_user: 22,
    email: 'jomara@eln.upv.es',
    org_pers_information: 'Universitat Politècnica de València (Campus de Gandia)',
    group_route: 0,
    description_en:
      'This route takes you through several villages in the region of Ribera Baja and La Safor, in which there are or were defensive constructions (towers, walls or castles). These towns, being located on the coast, have a large number of defensive constructions, which served to protect the town from possible pirate attacks from the sea.',
    geo_scope: 'Regional',
    keywords: ['CULTURAL', 'HERITAGE']
  },
  {
    id: 34,
    name: 'Elementos del Patrimonio Industrial y minero en riesgo',
    type: '',
    heritages_ids: [2, 3, 5],
    country: '',
    description_local: 'Elementos del Patrimonio Minero e Industrial en riesgo de desaparecer',
    tags_group: 94,
    links_interest: [{ url: '', name: '' }],
    date: '2023-01-12',
    id_user: 1,
    email: 'sandra@todamedia.com',
    org_pers_information: 'Universitat Politècnica de València (Campus de Gandia)',
    group_route: 0,
    description_en: 'Elements of the Mining and Industrial Heritage at risk of extinction',
    geo_scope: 'Regional',
    keywords: ['INDUSTRIAL']
  }
]

export default road_routes
