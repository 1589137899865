import http from '../http-common'
import road_groups from '../databases/road_groups.js'
import { ENV } from '../utils/constants.js'

const processHeritage = (heritageArray) =>{
  return heritageArray?.map((heritage) => {
    return ({
      ...heritage,
      heritage_type: heritage.heritage_field_id,
    //short_heritage_description: heritage.short_heritage_description,
    //short_heritage_description_local: heritage.short_local_heritage_description,
    //extended_heritage_description: heritage.extended_heritage_description,
    //extended_heritage_description_local: heritage.extended_local_heritage_description,
    heritageContent: heritage.heritageContent?.map((content) => {
      return ({
        ...content,
        file: `${ENV.PRODUCTION_S3_OBJECT_STORAGE}/heritage/content/${content.file}`
      })
    }),
    has_photos: heritage.documentation?.includes('photos'),
    has_audios: heritage.documentation?.includes('audios'),
    has_videos: heritage.documentation?.includes('videos'),
    has_maps: heritage.documentation?.includes('maps'),
    has_bibliography: heritage.documentation?.includes('bibliography'),
    image: heritage.image ? `${ENV.PRODUCTION_S3_OBJECT_STORAGE}/heritage/images/${heritage.image}` : null,
    tags: heritage.tags ?? [],
    //protected_values: heritage?.protected_values ? Object.keys(heritage?.protected_values).length === 0 ? {} : JSON.parse(heritage.protected_values) : {},
    //tags: heritage.tags?.split(',') ?? [],
    //protected_values: (heritage.protected_values && Object.keys(heritage.protected_values).length === 0)
    // ? {} : JSON.parse(heritage.protected_values),
  })

  })

}

class RoadService {
  async getAllRoadRoutes(limit, offset, filter) {
    const { data } = await http.post(
      `/api/${ENV.API_ROUTES.ROAD_ROUTES}?limit=${limit ?? 0}&offset=${offset ?? 0}`,
      filter
    )

    return data
  }
  getAllRoadGroups() {
    /** Commented until the backend is ready, meanwhile we're using mocked information
     * return http.get(`${ENV.API_ROUTES.USER}/` );
     */
    return road_groups
  }
  async getRoadRouteById(id) {
    const { data } = await http.get(`/api/${ENV.API_ROUTES.ROAD_ROUTES}/${id}`)
 
    return processHeritage(data?.heritages)
  }
  async createRoadRoute(road_route) {
    const { data } = await http.post(`/api/${ENV.API_ROUTES.ROAD_ROUTES}/create`, road_route)

    return data
  }
  async updateRoadRouteById(id, road_route) {
    const { data } = await http.put(`/api/${ENV.API_ROUTES.ROAD_ROUTES}/${id}`, road_route)

    return data
  }
  async deleteRoadRouteById(id) {
    const { data } = await http.delete(`/api/${ENV.API_ROUTES.ROAD_ROUTES}/${id}`)

    return data
  }
}
export default new RoadService()
