export const columns = [
  {
    name: 'Name',
    key: 'name',
    filter: 'text'
  },
  {
    name: 'Country',
    key: 'country_name',
    filter: 'text'
  },
  {
    name: 'State/Region',
    key: 'state_name',
    filter: 'text'
  }
]
