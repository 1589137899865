export const protected_values = [
  {
    id: '2',
    label: 'I to represent a masterpiece of human creative genius'
  },
  {
    id: '3',
    label:
      'II to exhibit an important interchange of human values, over a span of time or within a cultural area of the world, on developments in architecture or technology, monumental arts, town-planning, or landscape design'
  },
  {
    id: '4',
    label:
      'III to bear a unique or at least exceptional testimony to a cultural tradition or to a civilization which is living or has disappeared'
  },
  {
    id: '5',
    label:
      'IV to be an outstanding example of a type of building, architectural or technological ensemble or landscape which illustrates (a) significant stage(s) in human history'
  },
  {
    id: '6',
    label:
      'V to be an outstanding example of a traditional human settlement, land-use, or sea-use which is representative of a culture (or cultures), or human interaction'
  },
  {
    id: '7',
    label:
      'VI to be directly or tangibly associated with events or living traditions, with ideas, or with beliefs, with artistic and literary works of outstanding universal significance'
  },
  {
    id: '8',
    label:
      'VII to contain superlative natural phenomena or areas of exceptional natural beauty and aesthetic importance'
  },
  {
    id: '9',
    label:
      "VIII to be outstanding examples representing major stages of earth's history, including the record of life, significant ongoing geological processes in the development of landforms, or significant geomorphic or physiographic features"
  },
  {
    id: '10',
    label:
      'IX to be an outstanding example representing significant ongoing ecological and biological processes in the evolution and development of terrestrial, fresh water, coastal and marine ecosystems and communities of plants and animals.'
  },
  {
    id: '11',
    label:
      'X to contain the most important and significant natural habitats for in-situ conservation of biological diversity, including those containing threatened species of outstanding universal value from the point of view of science or conservation.'
  },
  {
    id: '12',
    label: '(A) Oral traditions and expressions, including language as a vehicle of the intangible cultural heritage'
  },
  {
    id: '13',
    label: '(B) Performing arts'
  },
  {
    id: '14',
    label: '(C) Social practices, rituals and festive events'
  },
  {
    id: '15',
    label: '(D) Knowledge and practices concerning nature and the universe'
  },
  {
    id: '16',
    label: '(E) Traditional craftsmanship'
  }
]
