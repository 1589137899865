import jwtDecode from 'jwt-decode'
import axios from 'axios'
import { ENV } from './utils'

const axiosInstance = axios.create({
  baseURL: `${ENV.BASE_API}/`,
  headers: {
    'Content-type': 'application/json'
  }
})

export const authHttpClient = axios.create({
  baseURL: `${ENV.AUTH_API}/`,
  headers: {
    'Content-type': 'application/json'
  }
})

let refreshTokenPromise // used to queue up requests when refreshing token

const setAuthToken = async (request) => {
  let token = localStorage.getItem(ENV.JWT.ACCESS)
  if (!token) return request

  const { exp } = jwtDecode(token)

  const currentDate = new Date().getTime()
  const expiredData = new Date(exp * 1000).getTime()

  if (currentDate > expiredData) {
    const refreshToken = localStorage.getItem(ENV.JWT.REFRESH)

    if (!refreshToken) {
      localStorage.removeItem(ENV.JWT.ACCESS)
      localStorage.removeItem(ENV.JWT.REFRESH)
      window.location.href = '/sign-in'
      return Promise.reject('No refresh token found')
    }

    // TODO make the refresh token requests once the auth api is ready
    refreshTokenPromise ??= axios.post(
      `${ENV.AUTH_API}/api/v1_1/auth/refresh-token`,
      { refresh_token: refreshToken },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    )

    try {
      const { data } = await refreshTokenPromise
      refreshTokenPromise = null
      token = data.token
      localStorage.setItem(ENV.JWT.ACCESS, token)
      localStorage.setItem(ENV.JWT.REFRESH, data.refresh_token)
    } catch (error) {
      console.log('error', error)
      localStorage.removeItem(ENV.JWT.ACCESS)
      localStorage.removeItem(ENV.JWT.REFRESH)
      window.location.href = '/sign-in'
      return Promise.reject(error)
    }
  }

  request.headers.set('Authorization', `Bearer ${token}`)
  return request
}

axiosInstance.interceptors.request.use(
  async (request) => await setAuthToken(request),
  (error) => {
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401 || error.code === 'ERR_NETWORK') {
      localStorage.clear()
      // window.location.reload()
    }
    return Promise.reject(error)
  }
)

authHttpClient.interceptors.request.use(
  async (request) => await setAuthToken(request),
  (error) => {
    return Promise.reject(error)
  }
)

export default axiosInstance
